@import 'scss/variables';
@import 'scss/typography';

.no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    & .main {
        margin: 0;
        margin-top: 26px;
        @include h5;
        color: $gray-900;
    }
    & .sub {
        margin: 0;
        margin-top: 8px;
        color: $gray-900;
        @include strong;
        & .stress {
            color: $red-500;
        }
    }
}