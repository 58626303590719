@import 'scss/variables';
@import 'scss/typography';

.layout-header {
  position: fixed;
  top: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px;
  width: 100%;
  max-width: 100vw;
  height: 64px;
  background-color: $gray-50;
  box-shadow: 2px 0px 15px rgba(0, 0, 0, 0.1);

  & .left-side {
    display: flex;
    position: relative;
    transition: left 0.6s;
    left: 0;

    &.move-left {
      left: -170px;
    }

    & .header-logo {
      width: 102px;
      height: 20px;
      margin-right: 130px;
      cursor: pointer;

      & img {
        width: 100%;
        height: 100%;
      }
    }

    & .company-name {
      position: relative;
      display: flex;
      align-items: center;
      color: $neon-blue-500;
      font-family: 'Manrope';
      font-weight: bold;
      & img {
        margin: 0 24px;
      }

      button {
        @include button-regular;
        color: $neon-blue-500;
      }
      & .shop-button-wrapper {
        position: relative;
        & .shop-list {
          position: absolute;
          top: calc(40px + 10px);
          left: -16px;
          padding: 8px 0;
          height: fit-content;
          background-color: $gray-50;
          border-radius: 3px;
          box-shadow: 2px 0px 15px rgba(0, 0, 0, 0.1);
          @include body-small;
          color: $gray-700;
          & div {
            display: flex;
            align-items: center;
            padding: 4px 16px;
            width: 100%;
            min-width: max-content;
            height: 40px;
            color: $gray-700;
            @include body-small;
            cursor: pointer;
            &:hover {
              background-color: #f1fbfc;
            }
          }
        }
      }
    }
  }

  & .right-side {
    display: flex;
    align-items: center;
    & img {
      cursor: pointer;
      margin-left: 12px;
    }

    button {
      margin-left: 16px;
      padding: 0;
      border: none;
      @include body-small;
      color: $gray-900;
      & .dropdown-button {
        display: flex;
        align-items: center;
        & .user-image {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          width: 24px;
          height: 24px;
          border-radius: 20px;
          background-color: $aqua-blue-500;
          font-family: 'Manrope';
          font-size: 14px;
          color: $white;
        }
        & .user-name {
          font-size: 14px;
          color: $gray-700;
        }
      }
    }
    & .dropdown-list {
      position: absolute;
      top: calc(60px + 10px);
      right: 8px;
      width: 167px;
      padding: 8px 0;
      height: fit-content;
      display: flex;
      flex-direction: column;
      border-radius: 3px;
      box-shadow: 2px 0px 15px rgba(0, 0, 0, 0.1);
      background-color: $gray-50;
      & a {
        display: flex;
        align-items: center;
        padding: 4px 0 4px 16px;
        height: 40px;
        color: $gray-700;
        @include body-small;
        &:hover {
          background-color: #f1fbfc;
        }
      }
    }

    & .dropdown-language {
      position: absolute;
      top: calc(60px + 10px);
      right: 126px;
      width: 62px;
      padding: 8px 0;
      height: fit-content;
      display: flex;
      flex-direction: column;
      border-radius: 3px;
      box-shadow: 2px 0px 15px rgba(0, 0, 0, 0.1);
      background-color: $gray-50;
      & a {
        display: flex;
        align-items: center;
        padding: 4px 0 4px 16px;
        height: 40px;
        color: $gray-700;
        @include body-small;
        &:hover {
          background-color: #f1fbfc;
          cursor: pointer;
        }
      }
    }
  }
}
