@import 'scss/variables.scss';

.TableOriginal {
  table {
    max-width: 100%;
    border-collapse: collapse;
    border-radius: 10px;
    border-style: hidden;
    box-shadow: 0 0 0 1px $gray-300;
  }

  .table_head_row {
    background-color: $gray-100;
    color: $gray-700;
    font-size: 14px;
    text-align: center;
    font-family: 'Pretendard';
    font-style: normal;
  }
  .logo_box {
    display: inline;
    width: 17px;
    height: 17px;
    position: relative;
  }
  .info_logo {
    width: 17px;
    height: 17px;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    margin-left: 3px;
  }

  // 인포 텍스트 관련
  .info_box {
    display: none;
  }

  .logo_box:hover + .info_box {
    display: block;
    position: absolute;
    left: 2px;
    top: -22px;
    width: 327px;
    padding: 8px 10px;
    border-radius: 4px;
    background-color: $white;
    color: $gray-900;
    font-family: 'Pretendard';
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;

    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.08))
      drop-shadow(2px 4px 12px rgba(0, 0, 0, 0.12));

    //말풍선 윗부분 삼각형
    .triangle {
      position: absolute;
      bottom: -4px;
      left: 150px;
      width: 12px;
      height: 12px;
      transform: rotate(45deg);
      background-color: $white;
    }
  }

  th {
    min-width: 145px;
    height: 62px;
    // padding: 24px 52px 24px 59px;
    // padding: 24px auto 24px;

    position: relative;

    &.large {
      width: 773px;
    }
    &:nth-of-type(5) {
      min-width: 170px;
    }
    &:first-child {
      width: 204px;
      max-width: 204px;
      // padding-left: 59px;
      border-top-left-radius: 10px;
    }

    &:nth-child(5) {
      width: 204px;
    }

    &:last-child {
      // padding-right: 59px;
      border-top-right-radius: 10px;
    }

    &.highlight {
      color: $red-500;
    }
  }

  .table_body_row {
    color: $gray-900;
    font-size: 14px;
    line-height: 18x;
    // border: 1px solid $gray-300;
    font-family: 'Pretendard';
    font-style: normal;
    &:hover {
      background-color: $neon-blue-50;
    }

    .table_td {
      padding: 24px auto;
      text-align: center;

      &:first-child {
        width: 204px;
        max-width: 204px;
        padding: 24px;
      }
      .latest_transaction {
        text-align: left;
      }
      span {
        color: $gray-500;
      }
    }
  }
  & .no-data {
    margin-top: 50px;
  }
}
