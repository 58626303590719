@import 'scss/variables';
@import 'scss/typography';

.search-input {
  position: relative;
  z-index: 1;
  display: inline-flex;
  flex-direction: column;
  cursor: default;
  & .input-label {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 5px;
    @include body-regular;
    color: $gray-900;
    text-transform: capitalize;
  }
  & .input-wrapper {
    position: relative;
    &:hover {
      background-color: $gray-100;
    }
    & .chevron-down {
      position: absolute;
      top: 0px;
      right: 0px;
      padding-top: 17px;
      padding-right: 16px;
      padding-bottom: 17px;
      background-color: transparent;
      &.reverse {
        padding-left: 16px;
        transform: rotate(180deg);
      }

      &.disabled {
        opacity: 0.1;
      }
    }
    & .remove-text-icon {
      position: absolute;
      z-index: 1;
      top: 16px;
      right: calc(16px + 24px + 8px);
      cursor: pointer;
    }
    // did not set the color of placeholder yet.
    & .input {
      padding: 16px 48px 16px 16px;
      width: 100%;
      height: 56px;
      outline: none;
      border: 1px solid $gray-300;
      border-radius: 4px;
      background-color: transparent;
      @include body-regular;
      color: $gray-900;
      cursor: default;
      &:focus {
        background-color: $white;
        border-color: $neon-blue-500;
        color: $gray-900;
        caret-color: $neon-blue-500;
        cursor: text;
      }
      &:disabled {
        background-color: $gray-100;
        color: $gray-300;
      }
    }
  }
  & .options-list {
    position: absolute;
    top: 90px;
    list-style: none;
    width: 100%;
    height: fit-content;
    max-height: 218px;
    overflow-y: auto;
    border: 1px solid $gray-300;
    background-color: $white;
    border-radius: 4px;
    z-index: 15;
    & .option {
      display: flex;
      align-items: center;
      padding: 12px 16px;
      width: 100%;
      height: fit-content;
      min-height: 56px;
      @include body-regular;
      color: $gray-900;
      cursor: pointer;
    }
    & .option.selected {
      color: $neon-blue-500;
    }
    & .option:hover {
      background-color: $gray-100;
    }
  }
}

.tooltip-box {
  &:hover {
    z-index: 5;
  }
  position: absolute;
  left: 34%;
  top: 0px;
  align-items: center;
  justify-content: center;
  height: 36px;
  .tooltip-info-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: 6px;
    .tooltip {
      position: absolute;
      width: 270px;
      height: 36px;
      left: -114px;
      top: -43px;
      padding: 8px 10px;
      background-color: $white;
      border-radius: 4px;
      filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.08))
        drop-shadow(2px 4px 12px rgba(0, 0, 0, 0.12));
      @include small-text;
      color: $gray-900;
    }
    .tooltip:after {
      content: '';
      position: absolute;
      left: 120px;
      bottom: -7px;
      width: 0;
      height: 0;
      border-top: 8px solid $white;
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
    }
  }
}
.period-tooltip-box {
  &:hover {
    z-index: 5;
  }
  height: 24px;
  align-items: center;
  justify-content: center;
  .tooltip-info-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-left: 6px;
    .tooltip {
      position: absolute;
      width: 270px;
      height: 52px;
      left: -4px;
      top: -60px;
      padding: 8px 10px;
      background-color: $white;
      border-radius: 4px;
      filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.08))
        drop-shadow(2px 4px 12px rgba(0, 0, 0, 0.12));
      @include small-text;
      color: $gray-900;
    }
    .tooltip:after {
      content: '';
      position: absolute;
      left: 9px;
      bottom: -7px;
      width: 0;
      height: 0;
      border-top: 8px solid $white;
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
    }
  }
}
