@import 'scss/variables';
@import 'scss/typography';

.legend-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  &_checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    margin: 0;
    height: 18px;
    width: 18px;
    border-radius: 3px;
    border: 1px solid $gray-300;
    background-color: $white;
    transform: translateY(-50%);

    // &:after {
    //   position: absolute;
    //   left: 2px;
    //   top: -3px;
    //   content: url('../../../assets/icons/legend_checkbox-check.svg');
    // }
  }
  &:hover input ~ &_checkmark {
    border: 1px solid $neon-blue-50;
    background-color: $neon-blue-50;
    &.disable {
      border: 1px solid $neon-blue-50;
      background-color: $neon-blue-50;
    }
  }
  & input:checked ~ &_checkmark {
    border: 1px solid $neon-blue-500;
    background-color: $neon-blue-500;
    &:after {
      position: absolute;
      left: 2px;
      top: -3px;
      content: url('../../../assets/icons/legend_checkbox-check.svg');
    }
    &.disable {
      border: 1px solid $neon-blue-50;
      background-color: $neon-blue-50;
    }
  }
  & input:checked ~ &_checkmark:after {
    display: block;
  }
  &_label {
    display: flex;
    align-items: center;
    margin-left: 29px;
    & img {
      margin-right: 10px;
    }
    & div {
      width: fit-content;
      max-width: 220px;
      @include chart-legend;
    }
    &.disable {
      color: $gray-300;
    }
  }
}
