@import 'scss/variables';
@import 'scss/typography';

.full-layout-container {
  width: 100%;
  min-height: 100vh;
  background-color: $gray-50;
  & .layout-main {
    position: relative;
    margin-top: 64px;
    margin-left: 240px;
    background-color: $gray-50;
    min-height: calc(100vh - 120px); // header랑 footer를 뺀 값
    &.minimized {
      margin-left: 60px;
    }
    &.full {
      margin-left: 0;
    }
    & .main-contents {
      margin: 0 auto;
      padding: 32px 24px;
      width: calc(1440px - 240px);
      background-color: $gray-50;
    }
  }

  .error-message-container {
    text-align: center;
    padding-top: 120px;
    font-family: 'Manrope';

    h1 {
      font-size: 64px;
      line-height: 72px;
      margin: 40px 0px 0px 0px;
    }

    h3 {
      font-size: 24px;
      line-height: 32px;

      margin-top: 24px;
    }

    img {
      width: 155px;
    }
  }
}
