@import 'scss/variables.scss';
@import 'scss/typography';

.box {
  width: 100%;
  height: auto;
  background-color: $white;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  &.LeadToCustomer {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    padding: 25px 24px 30px;
    & .row {
      display: flex;
      position: relative;
      &:nth-of-type(2) {
        display: flex;
        margin-top: 30px;
        justify-content: center;
      }
      & .search-input {
        margin-right: 15px;
      }
    }
  }

  & .ltc-toggles-container {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    & .ltc-toggles {
      display: flex;
      .ltc-toggle + .ltc-toggle {
        margin-left: 24px;
      }
      & .ltc-toggle {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include strong;
        color: $gray-900;
        & .tooltip-info-container {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          margin-left: 6px;
          & .tooltip {
            position: absolute;
            height: 56px;
            top: -60px;
            left: -3px;
            padding: 8px 10px;
            background-color: $white;
            border-radius: 4px;
            filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.08))
              drop-shadow(2px 4px 12px rgba(0, 0, 0, 0.12));
            @include small-text;
            color: $gray-900;
            &.ltv {
              width: 400px;
            }
            &.retention {
              width: 250px;
            }
          }
          & .tooltip:after {
            content: '';
            position: absolute;
            left: 15px;
            bottom: -7px;
            width: 0;
            height: 0;
            border-top: 8px solid $white;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
          }
          & img {
            margin-right: 7px;
          }
        }
        & .toggle {
          position: relative;
          margin-left: 10px;
          width: 32px;
          height: 18px;
          background-color: $gray-300;
          border-radius: 100px;
          transition: all 0.3s ease;

          &:hover {
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
          }

          &.on {
            background-color: $neon-blue-500;
          }

          &.disabled {
            background-color: $gray-200;

            &.on {
              background-color: $neon-blue-50;
            }

            &:hover {
              box-shadow: none;
            }
          }

          &_btn {
            position: absolute;
            top: 50%;
            left: 2px;
            width: 14px;
            height: 14px;
            background-color: $white;
            border-radius: 100px;
            box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
            transform: translateY(-50%);
            transition: all 0.3s ease;

            &.disabled {
              box-shadow: none;
            }

            &.on {
              left: 50%;
            }
          }
        }
      }
    }
    & .ltc-export-button {
      display: flex;
      align-items: center;
      @include button-small;
      color: $neon-blue-500;
      user-select: none;
      cursor: pointer;
      & > img {
        margin-right: 2px;
      }
    }
  }

  &.ltc-container {
    position: relative;
    z-index: 0;
    margin-top: 24px;
    padding: 24px 24px 32px 24px;
    width: 1152px;
    height: fit-content;

    & .ltc-table-container {
      overflow-x: scroll;
      padding: 1px;
      & .ltc-table {
        width: 100%;
        border-collapse: collapse;

        border-radius: 10px;
        border-style: hidden;
        box-shadow: 0 0 0 1px $gray-300;
        background-color: $white;
        thead {
          width: 100%;
          height: 62px;
          background-color: $gray-100;
          @include chart-category;
          color: $gray-700;
          font-size: 14px;
          & > tr {
            & > th {
              &:first-child {
                border-top-left-radius: 10px;
              }
              &:last-child {
                border-top-right-radius: 10px;
              }
            }
          }
        }
        & > tbody {
          background-color: $white;
          @include chart-body;
          & > tr {
            &:nth-of-type(2n) {
              border-bottom: 1px solid $gray-300;
            }
            & > td {
              padding: 5px 10px;
              width: auto;
              width: 60px;
              min-width: 70px;
              height: 28px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              text-align: center;
              &:nth-of-type(1) {
                width: 85px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                & span.year {
                  width: 40px;
                  @include chart-tooltip-small;
                  color: $gray-500;
                  text-align: left;
                }
                & span.month {
                  display: inline-flex;
                  justify-content: flex-start;
                  width: 27px;
                }
              }
              &:nth-of-type(2) {
                padding: 0 10px;
              }

              &.off {
                color: $gray-700;
              }

              &.neon-blue-50 {
                background-color: $neon-blue-50;
                color: $black;
              }
              &.neon-blue-100 {
                background-color: $neon-blue-100;
                color: $black;
              }
              &.neon-blue-200 {
                background-color: $neon-blue-200;
                color: $black;
              }
              &.neon-blue-300 {
                background-color: $neon-blue-300;
                color: $black;
              }
              &.neon-blue-400 {
                background-color: $neon-blue-400;
                color: $black;
              }
              &.neon-blue-500 {
                background-color: $neon-blue-500;
                color: $white;
              }
              &.neon-blue-600 {
                background-color: $neon-blue-600;
                color: $white;
              }
              &.neon-blue-700 {
                background-color: $neon-blue-700;
                color: $white;
              }
              &.neon-blue-800 {
                background-color: $neon-blue-800;
                color: $white;
              }
              &.neon-blue-900 {
                background-color: $neon-blue-900;
                color: $white;
              }
              &.red-50 {
                background-color: $red-50;
                color: $black;
              }
              &.red-100 {
                background-color: $red-100;
                color: $black;
              }
              &.red-200 {
                background-color: $red-200;
                color: $black;
              }
              &.red-300 {
                background-color: $red-300;
                color: $black;
              }
              &.red-400 {
                background-color: $red-400;
                color: $black;
              }
              &.red-500 {
                background-color: $red-500;
                color: $black;
              }
              &.red-600 {
                background-color: $red-600;
                color: $black;
              }
              &.red-700 {
                background-color: $red-700;
                color: $white;
              }
              &.red-800 {
                background-color: $red-800;
                color: $white;
              }
              &.red-900 {
                background-color: $red-900;
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}
