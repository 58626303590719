@import 'scss/variables';
@import 'scss/typography';
.box {
  width: 100%;
  background-color: $white;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  & .title {
    @include chart-title;
    color: $gray-900;
    & img {
      margin-left: 4.5px;
    }
  }
}
.overview-skeleton-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.segmentation-export-button {
  align-self: end;
  display: flex;
  align-items: center;
  margin-left: 32px;
  @include button-small;
  color: $neon-blue-500;
  user-select: none;
  cursor: pointer;
  & > img {
    margin-right: 5px;
  }
}

.segmentation {
  position: relative;
  @include chart-legend;
  color: $black;
  & .buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 8px;
    & button:nth-of-type(1) {
      margin-right: 4px;
    }
    &.hide {
      visibility: hidden;
    }
  }
  & .title {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
    @include chart-title;
  }
  &-gotocheck {
    display: flex;
    justify-content: space-between;
    &.box {
      margin-bottom: 24px;
      height: 98px;
    }
    & p {
      margin: 0;
      @include chart-title;
      color: $gray-900;
    }
    & .gotocheck-button {
      display: flex;
      align-items: center;
      @include button-small;
      color: $neon-blue-500;
      cursor: pointer;
      & img {
        margin-right: 2px;
      }
    }
  }
  &-pst {
    position: relative;
    &.box {
      padding: 35px 24px 40px;
      height: 697px;
    }
    & .title {
      & .text {
        position: relative;
        .segmentation-pst_guide-tooltip {
          position: absolute;
          z-index: 3;
          top: 40px;
          right: -172px;
          width: 324px;
          height: 240px;
          background-color: $white;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 8px 10px;
          gap: 3px;
          border-radius: 4px;
          box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
          &_guide {
            @include body-small;
          }
          & img {
            width: 100%;
          }
          &_button_container {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            & button {
              width: 70px;
              height: 28px;
              background-color: $neon-blue-500;
              color: $white;
              padding: 3px 16px;
              @include button-small;
              border-radius: 100px;
              &:hover {
                background-color: $neon-blue-400;
              }
            }
          }
          &:after {
            content: '';
            position: absolute;
            left: 50%;
            top: -7px;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            transform: rotate(180deg);
            // box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
            border-top: 8px solid $white;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
          }
        }
      }
    }
    &_legend_filter {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 20px;
      margin-bottom: 12px;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;

      @include chart-legend-semibold;
      color: $gray-900;

      &_item {
        position: relative;
        display: flex;
        align-items: center;
        white-space: pre-line;
        cursor: pointer;
        & img {
          margin-right: 10px;
        }
        &.disable {
          @include chart-legend;
          cursor: default;
        }
        &.off {
          color: $gray-400;
        }
        & .info-icon {
          margin-right: 0;
          margin-left: 3px;
          cursor: default;
        }
        & div {
          display: flex;
          align-items: center;
        }
        .segmentation-pst_inactive-legend-tooltip {
          position: absolute;
          z-index: 3;
          top: -75px;
          right: -81px;
          width: 200px;
          height: 70px;
          background-color: $white;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 8px 10px;
          gap: 3px;
          border-radius: 4px;
          box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
          &_guide {
            @include small-text;
            white-space: pre-line;
          }
          &:after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: -7px;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            transform: rotate(180deg);
            // box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
            border-bottom: 8px solid $white;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
          }
        }
      }
    }
    &_legend_segments {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 15px;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
      @include chart-legend-semibold;
      color: $gray-900;
      &_item {
        display: flex;
        align-items: center;
        cursor: pointer;
        &.off {
          color: $gray-400;
        }
      }
      &_color {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        margin-right: 5px;
      }
    }
    &_chart {
      display: flex;
      margin-top: 48px;
      &_left {
        display: flex;
        align-items: center;
        width: 68px;
        height: 384px;
      }
      &_right {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
      }
      .tooltip {
        .tooltip-name {
          margin-bottom: 7px;
          @include chart-tooltip-small;
          color: $gray-900;
        }
        .tooltip-percentage {
          padding-bottom: 7px;
          margin-bottom: 7px;
          @include chart-tooltip-small;
          color: $gray-700;
          border-bottom: 1px solid $gray-300;
        }
      }
    }
  }
  &-rfm {
    &.box {
      margin-top: 24px;
      padding: 35px 24px 40px;
      height: fit-content;
    }
    &_legend {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 25px;
      gap: 20px;
      @include chart-legend-semibold;
      color: $gray-900;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
      &_item {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        &.off {
          color: $gray-400;
        }
        &.disable {
          @include chart-legend;
          cursor: default;
        }
      }
    }
    &_chart {
      position: relative;
      display: flex;
      gap: 10px;
      &_segments {
        z-index: 1;
        width: 142px;
      }
      &_recency {
        z-index: 1;
        width: 270px;
      }
      &_frequency {
        z-index: 1;
        width: 331px;
      }
      &_monetary_value {
        z-index: 1;
        width: 331px;
      }
      &_header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 73px;
        border-top: 1px solid $gray-300;
        border-bottom: 1px solid $gray-300;
        background-color: $gray-100;
        @include chart-category;
        color: $gray-700;
      }
      &_rows {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-top: 12px;
      }
      &_row {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 47px;
      }
      &_segment_color {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        margin-right: 5px;
      }
      &_highlight {
        position: absolute;
        top: 73px;
        z-index: 0;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-top: 12px;
        width: 100%;
        height: calc(100% - 73px);
        &_row {
          width: 100%;
          height: 47px;
          &.hover {
            background-color: $gray-100;
          }
        }
      }
      &_overlay {
        position: absolute;
        top: 73px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-top: 12px;
        width: 100%;
        height: calc(100% - 73px);
        &_row {
          width: 100%;
          height: 47px;
        }
      }
      &_tooltip {
        position: absolute;
        z-index: 3;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 13px 12px;
        min-width: 290px;
        border-radius: 5px;
        background-color: $white;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.12);
        visibility: hidden;
        .tooltip-name {
          margin-bottom: 7px;
          @include chart-tooltip-small;
          color: $gray-900;
        }
        .tooltip-population {
          padding-bottom: 7px;
          margin-bottom: 7px;
          @include chart-tooltip-small;
          color: $gray-700;
          border-bottom: 1px solid $gray-300;
        }
        .tooltip-value {
          display: flex;
          @include chart-tooltip-body;
          &_title {
            color: $gray-900;
            @include chart-tooltip-title;
            min-width: fit-content;
          }
          &_value {
            margin-left: 5px;
            color: $gray-900;
            white-space: nowrap;
          }
          &_variation {
            margin-left: 5px;
            display: flex;
            align-items: center;
            @include chart-tooltip-small;
            &.positive {
              color: $green-500;
              &:after {
                vertical-align: middle;
                content: url('../../assets/icons/variation_positive.svg');
              }
            }
            &.negative {
              color: $red-500;
              &:after {
                vertical-align: middle;
                content: url('../../assets/icons/variation_negative.svg');
              }
            }
          }
        }
        &:after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: -7px;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-top: 8px solid $white;
          border-right: 7px solid transparent;
          border-left: 7px solid transparent;
        }
      }
    }
  }
  &-ncc {
    &.box {
      margin-top: 24px;
      padding: 35px 24px 40px;
      height: fit-content;
    }
    &_legend_dates {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
      gap: 20px;
      @include chart-legend-semibold;
      color: $gray-900;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
      &_item {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        &.off {
          color: $gray-400;
        }
        &.disable {
          @include chart-legend;
          cursor: default;
        }
      }
    }
    &_legend_segments {
      display: flex;
      justify-content: flex-end;
      @include chart-legend-semibold;
      gap: 15px;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
      &_item {
        display: flex;
        align-items: center;
        cursor: pointer;
        &.off {
          color: $gray-400;
        }
      }
      &_color {
        width: 9px;
        height: 9px;
        border-radius: 50%;
        margin-right: 5px;
      }
      &_name {
      }
    }
    &_chart {
      display: flex;
      gap: 24px;
      margin-top: 25px;
      &_purchase_once {
        position: relative;
        z-index: 2;
      }
      &_purchase_all_categories {
        position: relative;
        z-index: 1;
      }
      &_purchased_categories {
        position: relative;
        z-index: 0;
      }

      &_title {
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top: 10px;
        @include chart-category;
        color: $gray-900;
        white-space: pre-line;
      }

      .tooltip-name {
        margin-bottom: 7px;
        @include chart-tooltip-small;
        color: $gray-900;
      }
    }
  }
}
.segmentation-edit-segmentation {
  z-index: 4;
  position: fixed;
  top: 0;
  left: 240px;
  height: 100vh;
  width: calc(100% - 240px);
  &_dimmed {
    z-index: 1;
    height: 100vh;
    background-color: $gray-300;
    opacity: 0.5;
  }
  &_modal {
    padding: 35px 24px 40px;
    position: absolute;
    z-index: 2;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 11;
    border-radius: 10px;
    background-color: $white;
    width: 1140px;
    height: fit-content;
    &_close {
      position: absolute;
      top: 24px;
      right: 24px;
    }
    &_title {
      @include chart-title;
    }
    &_inputs {
      .input-box {
        display: inline-block;
        margin-top: 30px;
        width: 344px;
        .label {
          margin-bottom: 5px;
          @include body-regular;
          color: $gray-900;
        }
        .input-wrapper {
          display: flex;
          align-items: center;
          padding-left: 16px;
          padding-right: 8px;
          width: 100%;
          height: 56px;
          border: 1px solid $gray-300;
          border-radius: 4px;
          background-color: $white;
          input {
            width: 312px;
            border: 0;
            background-color: inherit;
            color: $gray-900;
            @include body-regular;
            &:focus {
              outline: none;
            }
            &::placeholder {
              color: $gray-500;
            }
            &:focus::placeholder {
              color: transparent;
            }
          }
        }
        & + .input-box {
          margin-left: 30px;
        }
        &:nth-of-type(3n-2) {
          margin-left: 0px;
        }
      }
    }
    .segmentation-edit-segmentation_modal_button {
      display: inline-flex;
      justify-content: center;
      margin-top: 30px;
      width: 100%;
    }
  }
  &.no-sidebar {
    left: 70px;
    width: calc(100% - 70px);
  }
}
.segmentation-tables-container {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  & .segmentation-table-tab-buttons {
    width: fit-content;
    border-radius: 10px 10px 0px 0px;
    background-color: $white;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.07);
    & .segmentation-table-tab-button {
      padding: 15px 24px;
      background-color: transparent;
      @include button-regular;
      color: $gray-500;
      & svg {
        margin-right: 10px;
      }
      &.selected {
        color: $gray-900;
      }
    }
  }
  & .segmentation-tables {
    width: 1152px;
    // height: 1027px;
  }
}
.box.segmentation-tables {
  padding: 35px 24px 40px;
  border-top-left-radius: 0;
  & .segmentation-table-container {
    position: relative;
    margin-top: 40px;
    &:nth-of-type(1) {
      margin-top: 0px;
    }
    & .segmentation-table {
      margin-top: 32px;
      width: 100%;
      border-collapse: collapse;
      border-style: hidden;
      border-top: 1px solid $gray-300;
      border-bottom: 1px solid $gray-300;

      & > thead {
        height: 62px;
        border-bottom: 1px solid $gray-300;
        background-color: $gray-100;
        & > tr > th {
          @include chart-category;
          color: $gray-700;
          &.item-table-th {
            &:nth-of-type(1) {
              width: 15%;
            }
            &:nth-of-type(2) {
              width: 40%;
            }
            &:nth-of-type(3) {
              width: 15%;
            }
            &:nth-of-type(4) {
              width: 15%;
            }
            &:nth-of-type(5) {
              width: 15%;
            }
          }
          &.customers-table-th {
            &:nth-of-type(1) {
              width: 18%;
            }
            &:nth-of-type(2) {
              width: 16%;
            }
            &:nth-of-type(3) {
              width: 16%;
            }
            &:nth-of-type(4) {
              width: 18%;
            }
            &:nth-of-type(5) {
              width: 16%;
            }
            &:nth-of-type(6) {
              width: 16%;
            }
          }
        }
      }

      & > tbody {
        & > tr {
          height: 66px;
          border-bottom: 1px solid $gray-300;
          & > td {
            @include chart-body;
            color: $gray-900;
            text-align: center;
          }
        }
      }
      &.grouptobe {
        margin-top: 0px;
        & > thead {
          height: 34px;
          & > tr > th {
            &.group-table-th {
              &:nth-of-type(1) {
                width: 136px;
              }
              &:nth-of-type(2) {
                width: 309px;
              }
              &:nth-of-type(3) {
                width: 163px;
              }
            }
          }
        }
        & > tbody {
          & > tr {
            height: 50px;
            &:last-of-type {
              & > td {
                @include chart-legend-semibold;
              }
            }
          }
        }
      }
    }

    & > .segmentation-table-export-button {
      position: absolute;
      top: 30px;
      right: 0;
      display: flex;
      align-items: center;
      padding: 3px 8px;
      @include button-small;
      color: $neon-blue-500;
      user-select: none;
      cursor: pointer;
      & > img {
        margin-left: 2px;
      }
    }
    & > .segmentation-table-title {
      text-align: center;
      @include chart-title;
    }

    & > .segmentation-grouptobe-container {
      display: flex;
      gap: 24px;
      margin-top: 28px;
      .segmentation-grouptobe-left {
        text-align: center;
        white-space: pre-line;
        padding: 43px 74px;
        width: 472px;
        min-height: 284px;
        border-radius: 10px;
        background-color: $gray-100;
        &-title {
          @include chart-title;
          color: $gray-900;
        }
        .highlight {
          color: $neon-blue-500;
        }
        &-description {
          margin-top: 16px;
          @include body-regular;
          color: $gray-900;
        }
        &-button {
          margin-top: 30px;
        }
      }
      & > .segmentation-grouptobe-right {
        width: 608px;
      }
    }
  }
}
