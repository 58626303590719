@import 'scss/variables';
@import 'scss/typography';

@mixin skeleton-animation {
  position: relative;
  overflow-x: hidden;
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 1s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.skeleton-title {
  background-color: $gray-200;
  height: 32px;
  border-radius: 6px;
  @include skeleton-animation;
}

.skeleton-subtitle {
  background-color: $gray-200;
  height: 16px;
  border-radius: 6px;
  @include skeleton-animation;
}

.skeleton-textfield {
  background-color: $gray-200;
  height: 50px;
  border-radius: 6px;
  @include skeleton-animation;
}

.skeleton-parameters {
  position: relative;
  padding: 25px 24px 30px;
  margin-top: 32px;
  width: 1152px;
  background-color: $white;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  &-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  &-apply-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
  }
  &-apply {
    width: 124px;
    height: 50px;
    background: $gray-200;
    border-radius: 100px;
    @include skeleton-animation;
  }
}
.skeleton-parameter {
  position: relative;
  width: 260px;
  height: 82px;
  &-title {
    @include skeleton-animation;
    position: absolute;
    width: 200px;
    height: 16px;
    left: 0;
    top: 0;
    border-radius: 6px;
    background-color: $gray-200;
  }
  &-value {
    @include skeleton-animation;
    position: absolute;
    width: 260px;
    height: 50px;
    left: 0;
    bottom: 0;
    border-radius: 6px;
    background-color: $gray-200;
  }
}

.skeleton-overviewcard {
  position: relative;
  height: 96px;
  background-color: $white;
  border-radius: 6px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
  &-title {
    position: absolute;
    width: 79px;
    height: 16px;
    left: 16px;
    top: 20px;
    border-radius: 6px;
    background-color: $gray-200;
    @include skeleton-animation;
  }
  &-value {
    position: absolute;
    width: 130px;
    height: 32px;
    left: 16px;
    top: 30px;
    border-radius: 6px;
    background-color: $gray-200;
    @include skeleton-animation;
  }
  &:nth-of-type(4) {
    .skeleton-overviewcard-title {
      width: 30px;
    }
    .skeleton-overviewcard-value {
      width: 50px;
    }
  }
  &:nth-of-type(5) {
    .skeleton-overviewcard-title {
      width: 50px;
    }
    .skeleton-overviewcard-value {
      width: 80px;
    }
  }
  &:nth-of-type(6) {
    .skeleton-overviewcard-title {
      width: 40px;
    }
    .skeleton-overviewcard-value {
      width: 90px;
    }
  }
}

.skeleton-dynamic {
  background-color: $gray-200;
  border-radius: 6px;
  @include skeleton-animation;
}
