@import 'scss/variables.scss';

.TableCustomers::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.TableCustomers {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.TableCustomers {
  overflow: scroll;
  overflow-y: auto;
  border: solid 1px $gray-300;
  border-radius: 10px;
  max-height: 410px;

  table {
    max-width: 100%;

    border-collapse: collapse;
    border-radius: 10px;
    border-style: hidden;
    box-shadow: 0 0 0 1px $gray-300;
  }

  .table_head_row {
    position: sticky;
    top: 0;
    border-bottom: solid $gray-300 1px;
    background-color: $gray-100;
    color: $gray-700;
    font-size: 14px;
    text-align: center;
    font-family: 'Pretendard';
    font-style: normal;
  }
  .logo_box {
    display: inline;
    width: 17px;
    height: 17px;
    position: relative;
  }
  .info_logo {
    width: 17px;
    height: 17px;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    margin-left: 3px;
  }

  // 인포 텍스트 관련
  .info_box {
    display: none;
  }

  .logo_box:hover + .info_box {
    display: block;
    position: absolute;
    left: 2px;
    top: -22px;
    width: 327px;
    padding: 8px 10px;
    border-radius: 4px;
    background-color: $white;
    color: $gray-900;
    font-family: 'Pretendard';
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;

    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.08))
      drop-shadow(2px 4px 12px rgba(0, 0, 0, 0.12));

    //말풍선 윗부분 삼각형
    .triangle {
      position: absolute;
      bottom: -4px;
      left: 150px;
      width: 12px;
      height: 12px;
      transform: rotate(45deg);
      background-color: $white;
    }
  }
  thead {
    position: relative;
  }
  th {
    min-width: 145px;
    height: 62px;

    position: relative;

    &.large {
      width: 773px;
    }
    &:nth-of-type(5) {
      min-width: 170px;
    }
    &:first-child {
      width: 192px;
      max-width: 204px;

      border-top-left-radius: 10px;
    }

    &:nth-child(5) {
      width: 204px;
    }
    &:nth-child(6) {
      min-width: 240px;
      max-width: 240px;
    }

    &:last-child {
      border-top-right-radius: 10px;
    }

    &.highlight {
      color: $red-500;
    }
  }

  .table_body_row {
    overflow: auto;
    overflow-y: auto;
    vertical-align: middle;
    align-items: center;
    color: $gray-900;
    font-size: 14px;
    line-height: 18x;

    font-family: 'Pretendard';
    font-style: normal;
    border-bottom: solid $gray-300 1px;

    .itemText {
      padding: 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    &:hover {
      background-color: $neon-blue-50;
    }
    td:nth-child(n + 6) {
      min-width: 240px;
      max-width: 240px;
      height: 50px;
    }
    .table_td {
      padding: 12px 0;
      text-align: center;

      &:first-child {
        width: 204px;
        max-width: 204px;
        padding: 0;
      }
      &:nth-child(6) {
        padding: auto;
      }
      .latest_transaction {
        text-align: left;
      }
      span {
        color: $gray-500;
      }
    }
  }
  & .no-data {
    margin: 20px 0;
  }
}
