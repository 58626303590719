@import 'scss/variables';
@import 'scss/typography';

.chart-tab-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    &.vertical {
        flex-direction: column-reverse;
        transform: translateX(-32px)rotate(-90deg);
    }
    &_label {
        @include chart-category;
        color: $gray-700;
    }
    &_button {
        position: relative;
        height: 26px;
    }
    &_active {
        z-index: 1;
        position: absolute;
        height: 26px;
        border-radius: 20px;
        background-color: $neon-blue-500;
        box-shadow: 2px 1px 3px rgba(0, 0, 0, 0.2);
        transition: all 0.3s ease;
    }
    &_options {
        display: flex;
        border-radius: 20px;
        background-color: $gray-200;
        word-break: keep-all;
    }
    &_option {
        z-index: 2;
        padding: 6.5px 16px;
        border-radius: 20px;
        @include chart-axis-label;
        color: $gray-900;
        text-transform: capitalize;
        cursor: pointer;
        &.select {
            color: $white;
        }
    }
}