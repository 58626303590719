@import 'scss/variables';
@import 'scss/typography';

.primary-button {
    padding: 12px 23px;
    // width: 124px;
    height: 48px;
    border: 0;
    border-radius: 100px;
    background-color: $neon-blue-500;
    @include button-regular;
    color: $white;
    cursor: pointer;
    
    &:hover {
        background-color: $neon-blue-400;
    }
    &:active {
        background-color: $neon-blue-600;
    }
    &.disabled {
        background-color: $neon-blue-50;
        cursor: default;
    }
}