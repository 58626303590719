@import 'scss/variables';
@import 'scss/typography';

.box {
  padding: 35px 30px;
  width: 100%;
  background-color: $white;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  & .title {
    @include chart-title;
    color: $gray-900;
  }
  &.edit-information {
    margin-top: 30px;
    height: 410px;
  }
  &.data-history {
    margin-top: 30px;
    height: fit-content;
  }
  &.organization {
    .boxes {
      display: flex;
      flex-wrap: wrap;
      .input-box {
        display: inline-block;
        margin-top: 30px;
        width: 531px;
        .label {
          margin-bottom: 5px;
          @include body-regular;
          color: $gray-900;
        }
        .input-wrapper {
          display: flex;
          align-items: center;
          padding-left: 16px;
          padding-right: 8px;
          width: 100%;
          height: 56px;
          border: 1px solid $gray-300;
          border-radius: 4px;
          background-color: $white;
          &.disabled {
            background-color: $gray-100;
            border-color: $gray-100;
            pointer-events: none;
          }
          input {
            width: 499px;
            border: 0;
            background-color: inherit;
            color: $gray-900;
            @include body-regular;
            &:focus {
              outline: none;
            }
            &::placeholder {
              color: $gray-500;
            }
            &:focus::placeholder {
              color: transparent;
            }
          }
          .input-eye {
            margin-left: 8px;
            margin-right: 8px;
          }
          .input-error {
            margin-right: 8px;
          }

          &.invalid {
            border: 1px solid $red-500;
          }
        }
        & + .input-box {
          margin-left: 30px;
        }
        &:nth-of-type(3n) {
          margin-left: 0px;
        }
      }
      & + div {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        width: 100%;
        height: 52px;
        & .info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          @include body-regular;
          color: $gray-900;
        }
        & .button-box {
        }
      }
    }

    & table {
      margin-top: 30px;
      width: 100%;
      border-collapse: collapse;
      border-style: hidden;
      border-top: 1px solid $gray-300;
      border-bottom: 1px solid $gray-300;
      & > thead {
        height: 62px;
        border-bottom: 1px solid $gray-300;
        background-color: $gray-100;
        & > tr > th {
          @include chart-category;
          color: $gray-700;
          &:nth-of-type(1) {
            width: 64px;
          }
          &:nth-of-type(2) {
            width: 210px;
          }
          &:nth-of-type(3) {
            width: 347px;
          }
          &:nth-of-type(4) {
            width: 110px;
          }
          &:nth-of-type(5) {
            width: 140px;
          }
          &:nth-of-type(6) {
            width: 180px;
          }
        }
      }
      & > tbody {
        & > tr {
          height: 66px;
          border-bottom: 1px solid $gray-300;
          & > td {
            position: relative;
            @include chart-body;
            color: $gray-900;
            text-align: center;
          }
        }
      }
    }
  }
}
