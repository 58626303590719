@import 'scss/variables.scss';

.CrossSelling {
  display: flex;
  flex-direction: column;

  &_title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: $gray-900;
    // background-color: red;
    margin: 0;
  }
  &_description {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $gray-500;
  }

  &_search_box {
    display: flex;
    gap: 20px;
    flex-direction: column;
    // align-items: flex-end;
    width: 1152px;
    // height: 140px;
    margin-top: 32px;
    // margin-bottom: 24px;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    background-color: $white;
    padding: 24px;
    .button_layer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 56px;

      &::before {
        content: '';
      }
    }
  }
  &_table_box {
    width: 1152px;
    height: 1162px;
    margin-top: 24px;
    // height: auto;
    padding: 24px;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    background-color: $white;
    overflow: scroll;
    overflow-x: auto;

    .table_box_title {
      color: $gray-900;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      text-align: center;
      padding-top: 6px;
    }

    .table_box_export {
      display: flex;
      flex-direction: row;

      width: 100%;
      color: $neon-blue-500;
      text-align: right;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 15px;
      margin-bottom: 12px;
      justify-content: right;
      cursor: pointer;

      .export_img {
        width: 15px;
        height: 15px;
        margin-right: 2px;
        // position: relative;
        // top: 80%;
        // padding-top: 5px;
        // line-height: 22px;
      }
    }
    .CrossSellingTable {
      // border-radius: 10px;
      // border: 1px solid $gray-300;

      table {
        border-collapse: collapse;
        border-radius: 10px;
        border-style: hidden;
        box-shadow: 0 0 0 1px $gray-300;
      }

      .table_head_row {
        background-color: $gray-100;
        color: $gray-700;
        font-size: 14px;
        text-align: center;
      }
      .logo_box {
        display: inline;
        width: 17px;
        height: 17px;
        position: relative;
      }
      .info_logo {
        width: 17px;
        height: 17px;
        cursor: pointer;
        position: absolute;
        bottom: 0;
      }

      // 인포 텍스트 관련
      .info_box {
        display: none;
      }

      .logo_box:hover + .info_box {
        display: block;
        position: absolute;
        left: 5px;
        top: -20px;
        width: 250px;
        padding: 8px 10px;
        border-radius: 4px;
        background-color: $white;
        color: $gray-900;
        font-family: 'Pretendard';
        font-style: normal;
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;

        filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.08))
          drop-shadow(2px 4px 12px rgba(0, 0, 0, 0.12));

        //말풍선 윗부분 삼각형
        .triangle {
          position: absolute;
          top: 26px;
          left: 120px;
          width: 12px;
          height: 12px;
          transform: rotate(45deg);
          background-color: $white;
        }
      }

      th {
        min-width: 140px;
        // padding: 24px 52px 24px 59px;
        height: 62px;

        position: relative;

        &:first-child {
          width: 200px;
          padding-left: 15px;
          border-top-left-radius: 10px;
        }

        &:nth-child(2) {
          width: 204px;
        }

        &:last-child {
          // padding-right: 59px;
          border-top-right-radius: 10px;
        }
      }

      .table_body_row {
        color: $gray-900;
        font-size: 14px;
        line-height: 18x;
        // border: 1px solid $gray-300;
        &:hover {
          background-color: $neon-blue-50;
        }

        .table_td {
          padding: 24px 0;
          text-align: center;

          &:first-child {
            width: 204px;
            padding: 0;
          }
          &:nth-child(2) {
            padding: 0;
          }
        }
      }
    }
  }
}
