@import 'scss/variables';
@import 'scss/typography';

.purchased-item {
  &_search-input {
    position: relative;
    // z-index: 1;
    display: inline-flex;
    flex-direction: column;
    cursor: default;
  }
  &_input-label {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 5px;
    @include body-regular;
    color: $gray-900;
    text-transform: capitalize;
  }
  &_input-wrapper {
    position: relative;
    &:hover {
      background-color: $gray-100;
    }
    & .chevron-down {
      position: absolute;
      top: 0px;
      right: 0px;
      padding-top: 17px;
      padding-right: 16px;
      padding-bottom: 17px;
      background-color: transparent;
      &.reverse {
        padding-left: 16px;
        transform: rotate(180deg);
      }

      &.disabled {
        opacity: 0.1;
      }
    }
    & .remove-text-icon {
      position: absolute;
      z-index: 1;
      top: 16px;
      right: calc(16px + 24px + 8px);
      cursor: pointer;
    }
    // did not set the color of placeholder yet.
  }
  &_input {
    padding: 16px 48px 16px 16px;
    width: 100%;
    height: 56px;
    outline: none;
    border: 1px solid $gray-300;
    border-radius: 4px;
    background-color: transparent;
    @include body-regular;
    color: $gray-900;
    cursor: default;
    &:focus {
      background-color: $white;
      border-color: $neon-blue-500;
      color: $gray-900;
      caret-color: $neon-blue-500;
      cursor: text;
    }
    &:disabled {
      background-color: $gray-100;
      color: $gray-300;
    }
  }
  &_options {
    visibility: hidden;
    position: absolute;
    box-sizing: content-box;
    top: 90px;
    display: flex;
    flex-wrap: wrap;
    width: 500px;
    height: fit-content;
    overflow-y: scroll;
    background-color: $gray-300;
    border-radius: 4px;
    z-index: 15;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    &.open {
      visibility: visible;
    }
    &-search {
      position: relative;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      border: 1px solid $gray-300;
      background-color: $white;
      border-radius: 4px;
    }
    &-left {
      position: relative;
      width: 211px;
      height: 276px;
      border: 1px solid $gray-300;
      background-color: $white;
      border-radius: 4px;
    }
    &-right {
      position: relative;
      width: 289px;
      height: 276px;
      border: 1px solid $gray-300;
      background-color: $white;
      border-radius: 4px;
    }
    &-bottom-long {
      display: flex;
      flex-direction: column;
      // align-items: center;
      align-items: flex-start;
      padding: 13px 16px;
      width: 100%;
      height: 83px;
      border: 1px solid $gray-300;
      background-color: $white;
      border-radius: 4px;
      gap: 6px;
    }
    &-title {
      position: absolute;
      top: 1;
      @include chart-tooltip-small;
      color: $gray-700;
      padding: 20px 16px 4px;
      width: 100%;
      height: 36px;
      background-color: $white;
      border-radius: 4px;
    }
    &-list {
      margin-top: 36px;
      height: calc(48px * 5);
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      &-item {
        display: flex;
        align-items: center;
        padding: 12px 16px;
        height: 48px;
        white-space: pre;
        & span.selected {
          color: $neon-blue-500;
        }
        &.selected {
          color: $neon-blue-500;
        }
        &:hover {
          background-color: $gray-100;
        }
      }
    }
  }
}
