@import 'scss/typography';
@import 'scss/variables';

.overview-bar-chart-container.mc {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;
    & .overview-bar-chart-title {
        display: flex;
        justify-content: center;
        @include chart-title;
    }
    & .overview-bar-chart.mc {
        margin-top: 25px;
        .y-axis {
            .tick {
                line {
                    visibility: hidden;
                }
                text {
                    @include chart-axis-label;
                    color: $gray-700;
                }
            }
        }
        .x-axis {
            .tick {
                line {
                    visibility: hidden;
                }
                text {
                    @include chart-axis-label;
                    color: $gray-700;
                }
            }
        }
    }
    .tooltip {
        position: absolute;
        visibility: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 13px 12px;
        min-width: fit-content;
        height: 61px;
        border-radius: 5px;
        background-color: $white;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.12);
        pointer-events: none;
        .x {
            min-width: max-content;
            @include chart-tooltip-small;
            color: $gray-700;
        }
        .y {
            @include chart-tooltip-body;
            .label {
                color: $neon-blue-500;
            }
            .value {
                color: $gray-900;
            }
        }
    }
    .tooltip:after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -7px;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-top: 8px solid $white;
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;   
    }
    & .year-indicator {
        @include chart-axis-label;
        fill: $gray-700;
    }
}