@import 'scss/variables';
@import 'scss/typography';

.tab-button {
    position: relative;
    width: auto;
    height: 44px;
    background-color: $gray-200;
    border-radius: 100px;
    transition: all 0.3s ease;

    &_active {
      position: absolute;
      top: 50%;
      left: 0;
      width: 73px;
      height: 44px;
      background-color: $neon-blue-500;
      border-radius: 100px;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
      transform: translateY(-50%);
      transition: all 0.3s ease;

      &.disabled {
        box-shadow: none;
      }

    }
    .options {
        position: relative;
        top: 50%;
        display: flex;
        width: auto;
        justify-content: space-around;
        @include button-regular;
        color: $gray-900;
        transform: translateY(-50%);
        .option {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 16px;
            min-width: fit-content;
            width: fit-content;
            height: 44px;
            white-space: nowrap;
            cursor: pointer;
            &.selected {
                color: $white;
            }
        }
    }
}