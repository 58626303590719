@import 'scss/typography';
@import 'scss/variables';

.report-line-chart-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  & .report-line-chart {
  }
  & .tooltip {
    position: absolute;
    visibility: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 13px 12px;
    width: fit-content;
    height: 61px;
    border-radius: 5px;
    background-color: $white;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.12);
    pointer-events: none;
    & .x {
      width: max-content;
      @include chart-tooltip-small;
      color: $gray-700;
    }
    & .y {
      width: max-content;
      @include chart-tooltip-body;
      & .label {
        // min-width: fit-content;
        color: $gray-900;
        @include chart-tooltip-title;
      }
      & .value {
        // min-width: fit-content;
        color: $gray-900;
      }
    }
  }
  & .tooltip:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -7px;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-top: 8px solid $white;
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
  }
}
