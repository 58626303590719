@import 'scss/variables';
@import 'scss/typography';

.box {
  width: 100%;
  background-color: $white;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  &.comparison-parameters {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    margin-bottom: 24px;
    padding: 25px 24px 30px;
    & .row {
      display: flex;
      // justify-content: space-between;

      position: relative;
      width: 100%;
      flex-wrap: wrap;

      column-gap: 21.33px;
      row-gap: 20px;
      &.first {
        position: relative;
        z-index: 3;
        margin-top: 0;
      }
      &.second {
        position: relative;
        z-index: 2;
        margin-top: 20px;
      }
      &.third {
        display: flex;
        justify-content: center;
        margin-top: 30px;
      }
    }
  }
  &.ltv-changes-over-time-container {
    padding: 35px 24px 40px;
    & .title {
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
      @include chart-title;
    }
  }
  &.ltv-changes-over-time {
    padding: 35px 24px 40px;
    width: 732px;
    height: 314px;
  }
  &.average-new-customer-container {
    padding: 35px 24px 40px;
    & .title {
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
      @include chart-title;
    }
  }
  &.average-new-customer {
    padding: 35px 24px 40px;
    width: 732px;
    height: 314px;
  }

  & .comparison-multiline-legends {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    @include chart-legend-semibold;
    color: $gray-900;
    &_item {
      display: flex;
      align-items: center;
      cursor: pointer;
      &.off {
        color: $gray-400;
      }
      & img {
        margin-right: 10px;
      }
      &.disable {
        @include chart-legend;
        cursor: default;
      }
    }
    &_color {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }

  & .comparison-table-container {
    margin-top: 24px;
    width: 100%;
    padding: 1px;
    overflow-x: scroll;
    table.comparison-table {
      min-width: 100%;
      border-collapse: collapse;
      border-radius: 10px;
      border-style: hidden;
      box-shadow: 0 0 0 1px $gray-300;
      thead {
        height: 62px;
        border-radius: 10px 10px 0 0;
        // background-color: $gray-100;
        color: $gray-700;
        th {
          min-width: 82px;
          background-color: $gray-100;
          @include chart-category;
          color: $gray-700;
          &:first-of-type {
            border-radius: 10px 0 0 0;
            border-bottom: 1px solid $gray-300;
          }
          &:last-of-type {
            border-radius: 0 10px 0 0;
          }
        }
      }
      tbody {
        tr {
          height: 50px;
          td {
            text-align: center;
            &:not(:first-of-type) {
              border-top: 1px solid $gray-300;
              @include chart-body;
              color: $gray-900;
            }
            &:first-of-type {
              padding: 18px 12px;
              background-color: $gray-100;
              border-right: 1px solid $gray-300;
              @include chart-category;
              color: $gray-700;
            }
            & .difference {
              @include chart-tooltip-small;
              color: $neon-blue-500;
            }
          }
          &:last-of-type {
            td {
              &:first-of-type {
                border-radius: 0 0 0 10px;
              }
            }
          }
          &.separation {
            border-bottom: 2px solid $gray-700;
          }
        }
      }
    }
  }
  .comparison-export-button-container {
    display: flex;
    justify-content: flex-end;
  }

  .comparison-export-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $neon-blue-500;
    line-height: 15px;
    margin-bottom: 12px;
    @include button-small;

    img {
      margin-right: 3px;
    }
  }

  &.comparison-skeleton-container {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }
  &.comparison-guide {
    width: 348px;
    height: 314px;
    padding-left: 24px;
    padding-right: 24px;
    & img {
      width: 300px;
    }
  }
  & .title.comparison-title {
    margin-bottom: 0;
  }
  & .title.comparison-guide-title {
    margin-bottom: 16px;
    @include strong;
  }
}

.flex-wrapper {
  display: flex;
  gap: 24px;
}
