@import 'scss/variables.scss';

.FeatureBased {
  display: flex;
  flex-direction: column;

  &_title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: $gray-900;
    // background-color: red;
    margin: 0;
  }
  &_description {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $gray-500;
  }
  &_search_box {
    display: flex;
    gap: 20px;
    flex-direction: column;
    // align-items: flex-end;
    width: 1152px;
    // height: 140px;
    margin-top: 32px;
    // margin-bottom: 24px;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    background-color: $white;
    padding: 24px;

    .search_input_row {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      column-gap: 21.33px;
      row-gap: 20px;
    }
    .button_layer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 56px;

      &::before {
        content: '';
      }
    }
  }
  &_table_box {
    width: 1152px;
    height: 1162px;
    margin-top: 24px;
    // height: auto;
    padding: 24px;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    background-color: $white;
    overflow: scroll;
    overflow-x: auto;

    &.no_data {
      height: 640px;
    }

    .FeatureBased_box_title {
      color: $gray-900;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      text-align: center;
      padding-top: 6px;
    }

    .FeatureBased_box_export {
      display: flex;
      flex-direction: row;

      width: 100%;
      color: $neon-blue-500;
      text-align: right;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 15px;
      margin-bottom: 12px;
      justify-content: right;
      cursor: pointer;

      .export_img {
        width: 15px;
        height: 15px;
        margin-right: 2px;
        // position: relative;
        // top: 80%;
        // padding-top: 5px;
        // line-height: 22px;
      }
    }
    .csv_help_text {
      display: flex;
      padding: 16px;
      // margin-bottom: 24px;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      align-items: center;
      color: $gray-900;
    }
    th {
      min-width: 180px;
    }
    th:last-child {
      color: $red-500;
    }
    .logo_box:hover + .info_box {
      left: 9px;
      top: -22px;
    }
    .table_body_row .latest_transaction {
      padding-left: 40px;
    }
  }
}
