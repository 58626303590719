@import 'scss/variables';
@import 'scss/typography';

.report-cards {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  & .report-section {
    width: 564px;
    & .title {
      margin-bottom: 10px;
      @include strong;
    }
    & .report-card {
      position: relative;
      z-index: 1;
      margin-bottom: 20px;
      .tooltip-info-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        & .tooltip {
          position: absolute;
          width: 182px;
          height: 56px;
          left: 0;
          top: -70px;
          padding: 8px 10px;
          background-color: $white;
          border-radius: 4px;
          filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.08))
            drop-shadow(2px 4px 12px rgba(0, 0, 0, 0.12));
          @include small-text;
          color: $gray-900;
        }
        & .tooltip:after {
          content: '';
          position: absolute;
          left: 10px;
          bottom: -7px;
          width: 0;
          height: 0;
          border-top: 8px solid $white;
          border-right: 7px solid transparent;
          border-left: 7px solid transparent;
        }
      }
      & .report-card-title {
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 24px;
        width: 100%;
        height: 56px;
        box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
        @include strong;
        color: $white;
        transition: 0.5s;
        cursor: pointer;
        & .representative-data {
          display: flex;
          max-width: 95%;
          & span {
            &:nth-of-type(2) {
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;

              word-wrap: break-all;
            }
            &:nth-of-type(3) {
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            &:not(&:nth-of-type(1)) {
              margin-left: 16px;
            }
            margin-right: 16px;
          }
          & .stress {
            display: inline-flex;
            align-items: center;
            & svg {
              margin-left: 4px;
              &.negative {
                transform: rotate(180deg);
              }
            }
          }
        }

        & .open-arrow {
          display: flex;
          align-items: center;
          transition: 0.5s;
          transform: rotate(180deg);
        }
        & .open-arrow.open {
          transform: rotate(0deg);
        }

        &.good {
          background-color: $neon-blue-500;
          & .representative-data {
            & .stress {
              color: $neon-blue-500;
            }
          }
        }
        &.bad {
          background-color: $red-500;
          & .representative-data {
            & .stress {
              color: $red-500;
            }
          }
        }
        &.closed {
          border-radius: 10px;
          background-color: $white;
          color: $gray-900;
          &.seen {
            color: $gray-500;
            &.good {
              & .representative-data {
                & .stress {
                  color: $neon-blue-300;
                }
              }
            }
            &.bad {
              & .representative-data {
                & .stress {
                  color: $red-300;
                }
              }
            }
          }
        }
        &.open {
          border-radius: 10px 10px 0px 0px;
          & .representative-data {
            & .stress {
              color: $white;
            }
          }
        }
        &.seen:not(.open) {
          background-color: $gray-100;
        }
      }

      & .report-card-contents-container {
        position: relative;
        z-index: 2;
        & .report-card-contents {
          position: relative;
          top: 0;
          width: 100%;
          height: 319px;
          border-radius: 0px 0px 10px 10px;
          box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
          background-color: $white;
          & .report-card-contents-detail {
            position: relative;
            padding: 24px 24px 0;
            height: calc(198px + 24px);
            & .report-card-contents-parameters {
              width: 40%;
              & .parameter {
                margin-bottom: 4px;
                & .sort {
                  @include chart-category;
                  color: $gray-900;
                }
                & .value {
                  @include chart-body;
                  color: $gray-900;
                  word-wrap: break-word;
                }
              }
            }
            & .report-card-contents-chart {
              position: absolute;
              z-index: 3;
              top: 30px;
              right: 32px;
              width: 285px;
              height: 130px;
            }
            & .see-chart-button {
              position: absolute;
              right: 24px;
              bottom: 20px;
              width: 82px;
              height: 28px;
              border: 1px solid $neon-blue-500;
              border-radius: 100px;
              @include button-small;
              color: $neon-blue-500;
            }
          }
          & .report-card-contents-dash {
            margin: 24px;
            width: calc(100% - 48px);
            border-top: 1px solid $gray-300;
          }
          & .report-card-contents-evaluation {
            display: flex;
            justify-content: space-between;
            padding: 0 24px;
            @include chart-body;
            color: $gray-700;
            & .report-card-contents-text {
              display: flex;
              align-items: center;
              & img {
                margin-left: 4px;
              }
            }
            & .report-card-contents-thumbs {
              display: flex;
              justify-content: space-between;
              width: 64px;
              & > svg {
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    & .report-card.open {
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
    }
  }
}

.report-skeleton {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
