@import 'scss/variables';
@import 'scss/typography';

.error-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: $neon-blue-900;
    .error-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            width: 155px;
            height: 280px;
        }
        .error-title {
            margin-top: 40px;
            @include h2;
            color: #ffffff;
        }
        .error-description {
            margin-top: 24px;
            @include h6;
            color: #ffffff;
        }
        button.error-gohome {
            margin-top: 40px;
            padding: 12px 24px;
            height: 48px;
            @include button-regular;
            color: $neon-blue-500;
            background-color: #ffffff;
            border-radius: 100px;
        }
    }
}