@mixin h1 {
  font-family: 'Manrope';
  font-size: 96px;
  line-height: 104px;
  font-weight: 700;
}
@mixin h2 {
  font-family: 'Manrope';
  font-size: 64px;
  line-height: 72px;
  font-weight: 700;
}
@mixin h3 {
  font-family: 'Manrope';
  font-size: 48px;
  line-height: 56px;
  font-weight: 700;
}
@mixin h4 {
  font-family: 'Manrope';
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
}
@mixin h5 {
  font-family: 'Manrope';
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
}
@mixin h6 {
  font-family: 'Manrope';
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}
@mixin strong {
  font-family: 'Manrope';
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
@mixin big-number {
  font-family: 'Pretendard';
  font-size: 32px;
  line-height: 40px;
  font-weight: 600;
}
@mixin subtitle-large {
  font-family: 'Pretendard';
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}
@mixin subtitle-regular {
  font-family: 'Pretendard';
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
}
@mixin body-large {
  font-family: 'Pretendard';
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
}
@mixin body-regular {
  font-family: 'Pretendard';
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
@mixin body-small {
  font-family: 'Pretendard';
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}
@mixin small-text {
  font-family: 'Pretendard';
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}
@mixin footnote {
  font-family: 'Pretendard';
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}
@mixin button-regular {
  font-family: 'Manrope';
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
@mixin button-small {
  font-family: 'Manrope';
  font-size: 14px;
  line-height: 22px;
  font-weight: 700;
}
@mixin label {
  font-family: 'Pretendard';
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}

@mixin chart-title {
  font-family: 'Manrope';
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
}
@mixin chart-category {
  font-family: 'Pretendard';
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
}
@mixin chart-body {
  font-family: 'Pretendard';
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}
@mixin chart-axis-label {
  font-family: 'Pretendard';
  font-size: 13px;
  line-height: 13px;
  font-weight: 500;
}
@mixin chart-legend {
  font-family: 'Pretendard';
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
}
@mixin chart-legend-semibold {
  font-family: 'Pretendard';
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
}
@mixin chart-tooltip-title {
  font-family: 'Pretendard';
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
}
@mixin chart-tooltip-small {
  font-family: 'Pretendard';
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
}
@mixin chart-tooltip-body {
  font-family: 'Pretendard';
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
