@import 'scss/variables';
@import 'scss/typography';

.box {
    padding: 35px 30px;
    width: 100%;
    background-color:  $white;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    & .title {
        @include chart-title;
        color: $gray-900;
    }
    &.edit-profile {
        margin-top: 30px;
        height: 328px;
    }
    &.change-password {
        margin-top: 30px;
        height: fit-content;
    }
    &.profile {
        width: 1152px;
        .boxes {
            form {
                display: flex;
                flex-wrap: wrap;
                .input-box {
                    display: inline-block;
                    margin-top: 30px;
                    width: 344px;
                    .label {
                        margin-bottom: 5px;
                        @include body-regular;
                        color: $gray-900;
                    }
                    .input-wrapper {
                        display: flex;
                        align-items: center;
                        padding-left: 16px;
                        padding-right: 8px;
                        width: 100%;
                        height: 56px;
                        border: 1px solid $gray-300;
                        border-radius: 4px;
                        background-color: $white;
                        &.disabled {
                            background-color: $gray-100;
                            border-color: $gray-100;
                            pointer-events: none;
                        }
                        input {
                            width: 312px;
                            border: 0;
                            background-color: inherit;
                            color: $gray-900;
                            @include body-regular;
                            &:focus {
                                outline: none;
                            }
                            &::placeholder {
                                color: $gray-500;
                            }
                            &:focus::placeholder {
                                color: transparent;
                            }
                        }
                        .input-eye {
                            margin-left: 8px;
                            margin-right: 8px;
                        }
                        .input-error {
                            margin-right: 8px;
                        }
                    
                        &.invalid {
                            border: 1px solid $red-500;
                        }
                    }
                    .input-error-message {
                        margin-top: 4px;
                        margin-bottom: 4px;
                        padding-left: 16px;
                        @include body-small;
                        color: $red-500;
                    }
                    .validation-list {
                        padding-left: 16px;
                        list-style: none;
                        @include body-small;
                        & li {
                            display: flex;
                            align-items: center;
                            margin-top: 4px;
                            & img {
                                margin-right: 5px;
                            }
                        }
                    }
                    
                    & + .input-box {
                        margin-left: 30px;
                    }
                    &:nth-of-type(3n-2) {
                        margin-left: 0px;
                    }
                }
            }
            .forgot-password-box {
                @include body-small;
                & button {
                    @include button-small;
                    color: $neon-blue-500;
                }
            }
            .button-box {
                display: inline-flex;
                justify-content: flex-end;
                align-items: flex-end;
                margin-left: 30px;
                width: 344px;
                
                // button  {
                //     width: 120px;
                //     height: 48px;
                //     justify-content: center;
                //     align-items: center;
                //     background: $neon-blue-500;
                //     border-radius: 100px;
                //     @include button-regular;
                //     align-items: center;
                //     text-align: center;
                //     color: $gray-100;
                // }
            }
    
            & + .boxes {
                display: flex;
                justify-content: space-between;
                margin-top: 30px;
            }
        }
    }
}