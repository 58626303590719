@import 'scss/typography';
@import 'scss/variables';

.chart-container {
  position: relative;
}
.chart {
  & .y-axis {
    & .tick {
      & line {
        visibility: hidden;
      }
      & text {
        @include chart-axis-label;
        color: $gray-700;
      }
    }
  }
  & .x-axis {
    & path {
      visibility: hidden;
    }
    & .tick {
      & line {
        visibility: hidden;
      }
      & text {
        @include chart-axis-label;
        color: $gray-700;
      }
    }
  }
  & .pies {
    text {
      @include chart-axis-label;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
    }
  }
}
.tooltip {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 13px 12px;
  width: fit-content;
  border-radius: 5px;
  background-color: $white;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.12);
  // pointer-events: none;
  //   -webkit-user-select: none;
  //   -khtml-user-select: none;
  //   -moz-user-select: none;
  //   -o-user-select: none;
  //   user-select: none;
  & .tooltip-name {
    @include chart-tooltip-small;
    font-size: 11px;
    color: $gray-700;
  }
  & .tooltip-value {
    display: flex;
    align-items: center;
    @include chart-tooltip-body;
    &.inactive-customers-value {
      width: 192px;
      flex-wrap: wrap;
      & .tooltip-value_title {
        min-width: 192px;
      }
      & .tooltip-value_value {
        margin-left: 0px;
      }
    }
    &_title {
      color: $gray-900;
      @include chart-tooltip-title;
    }
    &_value {
      margin-left: 5px;
      color: $gray-900;
    }
    &_variation {
      margin-top: 2px;
      margin-left: 5px;
      display: flex;
      align-items: center;
      @include chart-tooltip-small;
      &.positive {
        color: $green-500;
        &:after {
          content: url('../../../assets/icons/variation_positive.svg');
        }
      }
      &.negative {
        color: $red-500;
        &:after {
          content: url('../../../assets/icons/variation_negative.svg');
        }
      }
      &.inactive_positive {
        color: $red-500;
        &:after {
          content: url('../../../assets/icons/variation_inactive_positive.svg');
        }
      }
      &.inactive_negative {
        color: $green-500;
        &:after {
          content: url('../../../assets/icons/variation_inactive_negative.svg');
        }
      }
    }
  }

  & .x {
    width: max-content;
    @include chart-tooltip-small;
    color: $gray-700;
  }
  & .y {
    width: max-content;
    & .label {
      @include chart-tooltip-title;
      color: $gray-900;
    }
    & .value {
      @include chart-tooltip-body;
      color: $gray-900;
    }
  }
}
.tooltip:after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -7px;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-top: 8px solid $white;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}
