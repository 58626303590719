@import 'scss/variables';

.Login {
  margin-bottom: 20px;
  width: 100%;
  height: calc(100% - 120px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &_box {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    width: 416px;
    height: 698px;
    // background-color: yellow;

    &_logo {
      width: 192px;
      height: 38px;
      margin-bottom: 40px;
      &_img {
        width: 100%;
        height: 100%;
      }
    }
    &_inclusion {
      width: 416px;
      height: 618px;
      box-shadow: 2px 0px 15px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      background: $white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .inclusion {
        width: 300px;
        height: 520px;
        margin-bottom: 2px;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        // background-color: orange;

        &_text {
          height: 24px;
          font-family: 'Manrope';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: $gray-900;
          margin-bottom: 24px;
        }
        &_google {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 24px;
          width: 100%;
          height: 40px;
          margin-bottom: 16px;
          background: #ffffff;
          
          border: 1px solid #DDDDDD;
          border-radius: 4px;
          
          color: $gray-600;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          cursor: pointer;

          // 임시

          // &:active {
          //   background-color: #f1f1f1;
          // }

          img {
            width: 18px;
            height: 18px;
            // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.084), 0px 1px 1px rgba(0, 0, 0, 0.168);
            // border-radius: 4px;
          }
        }
        &_contour {
          width: 100%;
          height: 24px;
          margin-bottom: 12px;
          position: relative;

          .line {
            display: inline-block;
            width: 100%;
            border-top: 1px solid $gray-300;
            margin-bottom: 1px;
          }
          .or {
            height: 24px;
            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: $gray-500;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-color: $white;
            padding: 0px 12px;
          }
        }

        .input_box {
          width: 100%;
          height: 356px;

          .input_box_text {
            width: 100%;
            height: 24px;
            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: $gray-900;
            margin: 0 0 5px;
          }

          .input_email,
          .input_password {
            & .eye {
              display: flex;
              align-items: center;
            }
            & img {
              margin: 0 3px;
            }
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            height: 56px;
            border: 1px solid $gray-300;
            border-radius: 4px;
            padding: 0 16px;
            margin-bottom: 36px;

            &.invalid {
              margin-bottom: 4px;
              border: 1px solid $red-500;
            }
          }

          .error {
            margin: 0 0 8px 0;
            color: $red-500;
            font-size: 14px;
            line-height: 24px;
          }

          .email,
          .password {
            width: 236px;
            height: 24px;
            border: none;
            // appearance: none;
            &:focus {
              outline: none;
            }
          }

          .remember_box {
            width: 100%;
            height: 24px;
            margin-bottom: 31px;
            display: flex;
            align-items: center;

            .remember_me {
              appearance: none;
              width: 18px;
              height: 18px;
              border: 1px solid $gray-300;
              border-radius: 3px;
            }
            .remember_me::after {
              border: solid $white;
              border-width: 0 2px 2px 0;
              content: '';
              display: none;
              height: 40%;
              left: 40%;
              position: relative;
              top: 20%;
              transform: rotate(45deg);
              width: 15%;
            }
            .remember_me:checked {
              background: $neon-blue-500;
            }
            .remember_me:checked::after {
              display: block;
            }

            .remember_text {
              height: 24px;
              font-family: 'Pretendard';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: $gray-700;
              margin-left: 7px;
            }
          }

          .login_button {
            width: 100%;
            height: 48px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px 24px;
            border-radius: 100px;
            background-color: $neon-blue-500;
            color: $gray-100;
            margin-bottom: 15px;
            cursor: pointer;

            &:disabled {
              background-color: $neon-blue-50;
              cursor: unset;
            }
          }
        }
        .forget_password {
          width: 100%;
          height: 24px;
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          margin-bottom: 0;

          span.highlighted {
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            color: $neon-blue-500;
            cursor: pointer;

            // &:hover {
            //   text-decoration: underline;
            // }
          }
        }
      }
    }
  }
}
