@import 'scss/variables';
@import 'scss/typography';

.box {
  padding: 35px 24px;
  width: 100%;
  background-color: $white;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  & .title {
    @include chart-title;
    color: $gray-900;
    &.center {
      display: flex;
      justify-content: center;
    }
  }
  &.members {
    margin-top: 30px;
    & .button-wrapper {
      margin-top: 32px;
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
      button {
        @include button-regular ;
        color: $neon-blue-500;
        
        img {
          margin-right: 6px;
          display: inline-block;
          width: 14px;
          height: 14px;
        }
      }
    }



    & table {
      width: 100%;
      border-collapse: collapse;
      border-style: hidden;
      border-top: 1px solid $gray-300;
      border-bottom: 1px solid $gray-300;
      & > thead {
        height: 62px;
        border-bottom: 1px solid $gray-300;
        background-color: $gray-100;
        & > tr > th {
          @include chart-category;
          color: $gray-700;
          &:nth-of-type(1) {
            width: 44px;
          }
          &:nth-of-type(2) {
            width: 164px;
          }
          &:nth-of-type(3) {
            width: 140px;
          }
          &:nth-of-type(4) {
            width: 224px;
          }
          &:nth-of-type(5) {
            width: 250px;
          }
          &:nth-of-type(6) {
            width: 94px;
          }
          &:nth-of-type(7) {
            width: 94px;
          }
          &:nth-of-type(8) {
            width: 44px;
          }
          &:nth-of-type(9) {
            width: 50px;
          }
        }
      }
      & > tbody {
        & > tr {
          height: 66px;
          border-bottom: 1px solid $gray-300;
          & > td {
            position: relative;
            @include chart-body;
            color: $gray-900;
            text-align: center;
            padding-top: 16px;
            padding-bottom: 16px;
            & input:focus::placeholder {
              color: transparent;
            }
            &:nth-of-type(1) {
              max-width: 175px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            &:nth-of-type(2) {
              & > input {
                & + .inputbox-error {
                  position: absolute;
                  z-index: 2;
                  top: 21px;
                  right: 20px;
                  cursor: default;
                }
              }
            }
            &:nth-of-type(5) {
              & > input {
                & + .inputbox-error {
                  position: absolute;
                  z-index: 2;
                  top: 21px;
                  right: -160px;
                  cursor: default;
                }
              }
            }
            &:nth-of-type(8) {
              & div {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 34px;
                img {
                  cursor: pointer;
                }
              }
            }
            &:nth-of-type(9) {
              & div {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 34px;
                img {
                  cursor: pointer;
                  &.saving {
                    cursor: default;
                    animation: spin 1200ms infinite linear;
                  }
                }
              }
            }
            & > div {
              margin: 0 auto;
            }
            & > input {
              padding: 8px 16px;
              z-index: 2;
              border: 1px solid $gray-300;
              border-radius: 4px;
              text-align: center;
              &:focus {
                border: 1px solid $neon-blue-500;
              }
              &.error {
                border: 1px solid $red-500;
              }
            }
            & > .team {
              width: 200px;
              height: 34px;
            }
            & > p.error-message {
              margin: 0;
              margin-left: 20px;
              @include body-small;
              color: $red-500;
              text-align: left;
              &.unseen {
                height: 15px;
                visibility: hidden;
              }
            }
            & > input.user-name {
              width: 140px;
              height: 34px;
              & ~ p.error-message {
                position: absolute;
                width: 140px;
                bottom: 8px;
              }
            }
            & > input.email {
              position: absolute;
              top: 16px;
              left: 12px;
              width: 414px;
              height: 34px;
              & ~ p.error-message {
                position: absolute;
                width: 290px;
                bottom: 8px;
              }
            }

            & > img {
              cursor: pointer;
            }
          }
          &.error {
            height: 82px;
            & > td {
              padding-top: 16px;
              vertical-align: top;
              &:nth-of-type(1) {
                & > div {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 34px;
                }
              }
              &:nth-of-type(8) {
                padding-top: 16px;
                & > div {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 100%;
                  height: 34px;
                }
              }
              &:nth-of-type(9) {
                padding-top: 16px;
                & > div {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 34px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.dropdown {
  position: relative;
  width: 116px;
  button {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px 8px 16px;
    width: 116px;
    height: 34px;
    border: 1px solid $gray-300;
    border-radius: 4px;
    @include chart-body;
    color: $gray-900;
  }
  & > div {
    position: absolute;
    z-index: 2;
    top: calc(34px + 5px);
    width: 100%;
    background-color: $white;
    border: 1px solid $gray-300;
    border-radius: 4px;
    & > div {
      display: flex;
      align-items: center;
      padding: 8px 16px;
      height: 34px;
      @include chart-body;
      color: $gray-900;
      cursor: pointer;
      &.active {
        background-color: $gray-100;
      }
    }
  }
}
