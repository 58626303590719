@import 'scss/variables';
@import 'scss/typography';

.layout-sidebar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    width: 240px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: $white;
    box-shadow: 2px 0px 15px rgba(0, 0, 0, 0.1);
    transition: left 0.6s;

  & .upperside {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 15px;
    padding-left: 24px;
    width: 100%;
    height: 64px;

    & .logo {
      width: 102px;
      height: 20px;
      cursor: pointer;
      & img {
        width: 100%;
        height: 100%;
      }
    }
    & .open-button {
      position: relative;
      width: 31px;
      cursor: pointer;
      right: 4px;
    }
    & .open-button.rotate {
      transform: rotate(180deg);
      right: 4px;
    }
  }
  
  & .navigation-container {
    overflow: auto;
    flex: 1;
  }

  & ul {
    list-style: none;
    font-size: 14px;
    color: $gray-700;
  }

  & ul.navigation {
    & > li {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 48px;
      cursor: pointer;
      &.minimized {
        justify-content: flex-end;
        padding-right: 19px;
      }
      &.minimized.hovered {
        justify-content: center;
        padding-right: 0;
      }
      & .active-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 24px 0 24px;
        width: 100%;
        height: 32px;
        border-radius: 10px;
        overflow-x: hidden;
        & a {
          position: inherit;
          left: 0;
          width: 100%;
          display: flex;
          align-items: center;
          white-space: nowrap;
          & svg {
            margin-right: 8px;
          }
        }
        & a.minimized {
          & svg {
            margin: 0;
          }
        }
        & .open-arrow {
          transition: all 0.5s;
        }
        & .open-arrow.open {
          transform: rotate(-90deg);
        }
      }
      & .active-area:hover {
        background-color: $gray-100;
        color: $gray-900;
      }
      & .active-area.open {
        background-color: $gray-100;
        color: $gray-900;
      }
      & .active-area.current-page {
        background-color: $neon-blue-50;
        color: $neon-blue-500;
      }
      & .active-area.open.minimized {
        background-color: transparent;
      }
      & .active-area.current-page.minimized {
        background-color: $neon-blue-50;
      }
      & .active-area.open.minimized.hovered {
        background-color: $gray-100;
      }

      & .active-area.minimized {
        width: 32px;
        padding: 0;
        & a {
          display: flex;
          justify-content: center;
        }
      }
      & .active-area.minimized.hovered {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 24px 0 24px;
        width: 100%;
        height: 32px;
        & a {
          position: inherit;
          left: 0;
          width: 100%;
          display: flex;
          justify-content: left;
          & svg {
            margin-right: 8px;
          }
        }
      }
    }
  }
  & div.sub-container {
    & ul.sub {
      & li {
        height: 40px;
        & a.sub-active-area {
          display: flex;
          align-items: center;
          padding-left: 55px;
          height: 32px;
        }
        & a.sub-active-area:hover {
          background-color: $gray-100;
          border-radius: 10px;
          color: $gray-900;
        }
        & a.sub-active-area.current {
          color: $neon-blue-500;
        }
      }
    }
  }
}
.layout-sidebar.minimized {
  left: -170px;
}
.layout-sidebar.minimized.hovered {
  left: 0;
}
