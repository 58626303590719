@import 'scss/variables';
@import 'scss/typography';

.advanced-button {
    width: 48px;
    padding: 12px;
    height: 48px;
    border: 1px solid $gray-300;
    border-radius: 4px;
    cursor:pointer;
    &:hover {
        border: 1px solid $neon-blue-50;
        background-color: $neon-blue-50;
        box-shadow: 2px 1px 3px rgba(0, 0, 0, 0.2);
    }
    &.disabled {
        border: 1px solid $gray-300;
        box-shadow: none;
        background-color: $gray-100;
        cursor: default;
    }
    &.pressed {
        transform: rotate(180deg);
    }
}