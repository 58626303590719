/* @tailwind base;
@tailwind components;
@tailwind utilities; */

* {
  box-sizing: border-box;
  *:focus {
    outline: none;
  }
}

body {
  margin: 0;
  font-family: 'Pretendard', 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  margin: 0;
  padding: 0;
}
a {
  color: inherit;
  text-decoration: inherit;
}
button {
  background: inherit ; 
  border:none; 
  box-shadow:none; 
  border-radius:0; 
  padding:0; 
  overflow:visible; 
  cursor:pointer;
}