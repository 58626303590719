$neon-blue-900: #060073;
$neon-blue-800: #0b03a6;
$neon-blue-700: #2118d9;
$neon-blue-600: #362ef2;
$neon-blue-500: #4b43ff;
$neon-blue-400: #6a62fe;
$neon-blue-300: #8681fc;
$neon-blue-200: #a8a3fb;
$neon-blue-100: #c1bef7;
$neon-blue-50: #e0dff8;

$aqua-blue-500: #00dcff;

$neon-green-500: #ce43ff;

$black: #000000;
$white: #ffffff;

$gray-900: #222222;
$gray-700: #616161;
$gray-600: #757575;
$gray-500: #9e9e9e;
$gray-400: #bdbdbd;
$gray-300: #dddddd;
$gray-200: #eeeeee;
$gray-100: #f5f5f5;
$gray-50: #fafafa;

$red-900: #ad0000;
$red-800: #ce1212;
$red-700: #da1b1b;
$red-600: #e93535;
$red-500: #f94c4c;
$red-400: #ff7070;
$red-300: #ff9999;
$red-200: #ffb3b3;
$red-100: #fad1d1;
$red-50: #fde7e7;

$green-500: #4caf50;
