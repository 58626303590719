@import 'scss/variables';
@import 'scss/typography';

.box {
  width: 100%;
  background-color: $white;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  & .title {
    @include chart-title;
    color: $gray-900;
  }
}
.overview-cards {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  min-width: 100%;
  & .box.card {
    padding: 20px;
    width: 100px;
    height: 96px;
    & > .card-title {
      @include body-small;
      color: $gray-900;
    }
    & > .card-value {
      @include h6;
      color: $gray-900;
      & > .ltc {
        margin-left: 4px;
        @include body-large;
      }
      & > .retention-rate {
        margin-left: 4px;
        @include body-large;
      }
      & > .ltv-currency {
        margin-right: 4px;
        @include body-large;
      }
    }
    &:nth-of-type(1) {
      width: 181px;
    }
    &:nth-of-type(2) {
      width: 170px;
    }
    &:nth-of-type(3) {
      width: 240px;
    }
    &:nth-of-type(4) {
      width: 104px;
    }
    &:nth-of-type(5) {
      white-space: nowrap;
      width: 132px;
    }
    &:nth-of-type(6) {
      width: 205px;
    }
  }
}
.box.sales-chart-container {
  position: relative;
  margin-top: 24px;
  padding: 35px 40px 40px 40px;
  width: 1152px;
  height: 590px;
  & > .tab-button {
    position: absolute;
    z-index: 1;
    top: 35px;
    right: 50px;
  }
  & > .overview-sales-chart-container {
    & > .overview-sales-chart {
      z-index: 0;
    }
    & > .tooltip {
      z-index: 2;
    }
  }
}
.overview-skeleton-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.overview-third-row-charts {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  & .box.new-lead-to-customer-chart-container {
    padding: 35px 24px 40px 24px;
    width: 564px;
    height: 443px;
  }
  & .box.monthly-customers-chart-container {
    padding: 35px 24px 40px 24px;
    width: 270px;
    height: 443px;
  }
  & .box.monthly-transaction-chart-container {
    padding: 35px 24px 40px 24px;
    width: 270px;
    height: 443px;
  }
}
.overview-rankings {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  & .box.category-ranking-chart-container {
    padding-top: 35px;
    padding-right: 24px;
    padding-left: 24px;
    width: 270px;
    height: 419px;
  }
  & .box.item-ranking-table-container {
    padding-top: 35px;
    padding-right: 24px;
    padding-left: 24px;
    width: 564px;
    height: 419px;
  }
  & .box.location-ranking-chart-container {
    padding-top: 35px;
    padding-right: 24px;
    padding-left: 24px;
    width: 270px;
    height: 419px;
  }
}
.overview-fifth-row-charts {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  & .box.month3-ltv-chart-container {
    padding: 35px 24px 40px;
    width: 367px;
    height: 330px;
  }
  & .box.month6-ltv-chart-container {
    padding: 35px 24px 40px;
    width: 367px;
    height: 330px;
  }
  & .box.abtesting-result-chart-container {
    padding: 35px 24px 40px;
    width: 368px;
    height: 330px;
  }
}
.box.customers-table-container {
  position: relative;
  margin-top: 24px;
  padding-top: 35px;
  padding-right: 24px;
  padding-left: 24px;
  width: 1152px;
  height: 419px;
}
.overview-table-container {
  position: relative;
  & > .overview-table-export-button {
    position: absolute;
    top: 30px;
    right: 0;
    display: flex;
    align-items: center;
    padding: 3px 8px;
    @include button-small;
    color: $neon-blue-500;
    user-select: none;
    cursor: pointer;
    & > img {
      margin-right: 2px;
    }
  }
  & > .overview-table-title {
    text-align: center;
    @include chart-title;
  }
  & > .overview-table {
    margin-top: 32px;
    width: 100%;
    border-collapse: collapse;
    border-style: hidden;
    border-top: 1px solid $gray-300;
    border-bottom: 1px solid $gray-300;

    & > thead {
      height: 34px;
      border-bottom: 1px solid $gray-300;
      background-color: $gray-100;
      & > tr > th {
        @include chart-category;
        color: $gray-700;
        &:nth-of-type(1) {
          width: 175px;
        }
      }
    }
    & > tbody {
      & > tr {
        height: 50px;
        border-bottom: 1px solid $gray-300;
        & > td {
          @include chart-body;
          color: $gray-900;
          text-align: center;
          &:nth-of-type(1) {
            padding: 0 24px;
            max-width: 175px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
