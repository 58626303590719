@import 'scss/variables.scss';

.DataCenter_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  .footer {
    bottom: 16px;
    color: $gray-700;
    position: relative;
    transform: translateY(-10%);

    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  .DataCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    min-height: 100%;
    margin-top: 64px;
    padding-bottom: 30px;

    &_phrases_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .message {
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
      }
      .analyzing_message {
        margin-bottom: 24px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
      }
      .loading_date {
        color: $gray-500;
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
      }
    }

    &_file_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 48px;
      margin-top: 16px;
      width: 560px;
      height: 696px;
      // background: #FFFFFF;
      box-shadow: 2px 0px 15px rgba(0, 0, 0, 0.1);
      border-radius: 10px;

      .DataCenterUpLoadingBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 464px;
        margin-bottom: 10px;

        &:nth-last-child(3) {
          margin-bottom: 39px;
        }

        &_title {
          color: $gray-900;
          font-weight: 700;
          line-height: 24px;
        }

        &_description {
          margin-bottom: 5px;
          color: $gray-900;
          font-size: 14px;
          line-height: 24px;
        }

        &_filebox {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          height: 72px;
          padding: 22px 24px;

          border: 1px solid $gray-300;
          border-radius: 6px;

          .file_img {
            margin-right: 13px;
          }

          .file_name {
            color: $gray-900;
            font-size: 14px;
            line-height: 24px;
            width: 208px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .file_status {
            position: absolute;
            right: 144px;
          }

          .btn_label {
            position: absolute;
            right: 24px;
            padding: 0 8px;
            border: 1px solid $neon-blue-500;
            border-radius: 100px;
            color: $neon-blue-500;
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
            cursor: pointer;
          }

          .btn_file {
            opacity: 0;
            display: none;
          }
        }
        .help_text_box {
          width: 100%;
          height: 24px;
        }
        .help_text {
          display: none;
          padding: 0 0 0 24px;
          color: $red-500;
          font-size: 14px;
          line-height: 24px;
        }

        .show {
          display: block;
          // visibility: visible;
        }
        .none {
          height: 24px;
          margin-bottom: 24px;
        }
      }

      .submit_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 464px;
        height: 48px;
        background: $neon-blue-500;
        border-radius: 100px;
        color: $gray-100;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        border: none;
        cursor: pointer;

        &:disabled {
          background: #e0dff8;
          cursor: default;
        }

        &_loading {
          animation: spin 1200ms infinite linear;
        }
      }

      @keyframes spin {
        0% {
          transform: rotate(0);
        }

        100% {
          transform: rotate(360deg);
        }
      }

      .go_dashboard {
        color: $neon-blue-500;
        font-size: 14px;
        font-weight: 700;
        margin-top: 16px;
      }
    }

    // analze
    &_analyze_box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 760px;
      height: 500px;
      margin-top: 16px;

      // left box
      .left_analyze {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 270px;
        height: 500px;
        background: #ffffff;
        box-shadow: 2px 0px 15px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 48px;

        .analyze_info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 174px;
          height: 264px;
          margin-bottom: 23px;

          &_box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 48px;
            list-style: none;

            .info_title {
              height: 24px;
              font-family: 'Manrope';
              font-weight: 700;
              font-size: 16px;
              color: $gray-900;
            }
            .info_number {
              width: 100%;
              font-family: 'Pretendard';
              font-weight: 400;
              font-size: 14px;
              color: $gray-900;
            }
          }
        }
        .updated_box span {
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          color: $gray-900;
        }
      }

      .line {
        width: 100%;
        border-top: 1px solid $gray-300;
        margin-bottom: 24px;
      }

      // right box
      .right_analyze {
        width: 466px;
        height: 500px;
        background: #ffffff;
        box-shadow: 2px 0px 15px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 48px;
        display: flex;
        flex-direction: column;
        position: relative;

        .check_box {
          display: flex;
          flex-direction: column;
          justify-items: flex-start;

          .ab_testing {
            margin-bottom: 22px;

            &_title {
              display: flex;
              align-items: center;
              gap: 9px;
              margin-bottom: 14px;
              color: $gray-900;
              font-weight: 700;
              line-height: 24px;
            }

            .info_box {
              box-sizing: border-box;
              position: relative;
              width: 18px;
              height: 18px;
            }

            .info_logo {
              width: 17px;
              height: 17px;
              cursor: pointer;
            }

            // 인포 텍스트 관련
            .info_text {
              display: none;
            }
            .info_logo:hover + .info_text {
              display: block;
              position: absolute;
              left: -9px;
              width: 287px;
              padding: 8px 10px;
              border-radius: 4px;
              background-color: $neon-blue-500;
              color: $white;
              font-size: 12px;
              font-weight: 400;
              line-height: 20px;

              //말풍선 윗부분 삼각형
              .triangle {
                position: absolute;
                top: -4px;
                left: 12px;
                width: 8px;
                height: 12px;
                transform: rotate(45deg);
                background-color: $neon-blue-500;
              }
            }
            // 토글
            .toggle {
              position: relative;
              width: 32px;
              height: 18px;
              background-color: $gray-300;
              border-radius: 100px;
              transition: all 0.3s ease;

              &:hover {
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
              }

              &.on {
                background-color: $neon-blue-500;
              }

              &.disabled {
                background-color: $gray-200;

                &.on {
                  background-color: $neon-blue-50;
                }

                &:hover {
                  box-shadow: none;
                }
              }

              &_btn {
                position: absolute;
                top: 50%;
                left: 2px;
                width: 14px;
                height: 14px;
                background-color: $white;
                border-radius: 100px;
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
                transform: translateY(-50%);
                transition: all 0.3s ease;

                &.disabled {
                  box-shadow: none;
                }

                &.on {
                  left: 50%;
                }
              }
            }
          }

          // 클러스터링
          .clustering {
            margin-bottom: 24px;

            &_title {
              margin-bottom: 12px;
              color: $gray-900;
              font-weight: 700;
              line-height: 24px;
            }

            &_selec_box {
              display: flex;
              flex-direction: column;
              gap: 12px;
              font-size: 14px;
              line-height: 24px;
            }

            &_selec_item {
              display: flex;
              align-items: center;
              gap: 8px;
            }
          }

          // Currency
          .currency {
            // background-color: yellow;

            margin-bottom: 29px;
            &_title {
              margin-bottom: 12px;
              color: $gray-900;
              font-weight: 700;
              line-height: 24px;
            }

            &_selec_box {
              display: flex;
              flex-direction: row;
              gap: 12px;
              font-size: 14px;
              line-height: 24px;
            }
            &_selec_item {
              display: flex;
              align-items: center;
              gap: 8px;
            }
          }
        }
        .bottom_buttons {
          width: 370px;
          display: flex;
          flex-direction: column;
          position: absolute;
          bottom: 48px;
        }
        // 분석 시작 버튼
        &_submit_btn {
          background-color: $neon-blue-500;
          height: 48px;
          margin-bottom: 19px;
          border: none;
          border-radius: 100px;
          color: $white;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          outline: none;
          cursor: pointer;

          &.disable {
            background-color: $neon-blue-50;
            cursor: default;
          }
        }

        .return_file {
          color: $neon-blue-500;
          font-size: 14px;
          font-weight: 700;
          line-height: 22px;
          text-align: center;
          cursor: pointer;
        }
      }
    }

    // analyzing
    &_analyzing_box {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 52px 48px 48px;
      margin-top: 16px;
      width: 560px;
      height: 392px;

      box-shadow: 2px 0px 15px rgba(0, 0, 0, 0.1);
      border-radius: 10px;

      .submitted_info_box {
        width: 464px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .check_filled {
          height: 70px;
          width: 70px;
          margin-bottom: 15px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .data_submitted {
          margin-bottom: 5px;
          font-family: 'Manrope';
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 32px;
        }
        .data_submitted_time {
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: $gray-500;
        }
        .data_submitted_files {
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: $gray-500;
          span {
            padding: 0 7px;
            border-right: solid 1px $gray-300;
          }
          :last-child {
            border-right: none !important;
          }
        }
      }
      .DataCenterUpLoadingBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 464px;
        margin-bottom: 10px;

        &:nth-last-child(3) {
          margin-bottom: 39px;
        }

        &_title {
          color: $gray-900;
          font-weight: 700;
          line-height: 24px;
        }

        &_description {
          margin-bottom: 5px;
          color: $gray-900;
          font-size: 14px;
          line-height: 24px;
        }

        &_filebox {
          position: relative;
          display: flex;
          align-items: center;
          width: 100%;
          height: 72px;
          padding: 22px 24px;

          border: 1px solid $gray-300;
          border-radius: 6px;

          .file_img {
            margin-right: 13px;
          }

          .file_name {
            color: $gray-900;
            font-size: 14px;
            line-height: 24px;
            width: 208px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .file_status {
            position: absolute;
            right: 144px;
          }

          .btn_label {
            position: absolute;
            right: 24px;
            padding: 0 8px;
            border: 1px solid $neon-blue-500;
            border-radius: 100px;
            color: $neon-blue-500;
            font-size: 14px;
            font-weight: 700;
            line-height: 22px;
            cursor: pointer;
          }

          .btn_file {
            opacity: 0;
            display: none;
          }
        }
        .help_text_box {
          width: 100%;
          height: 24px;
        }
        .help_text {
          // visibility: hidden;
          display: none;
          padding: 0 0 0 24px;
          color: $red-500;
          font-size: 14px;
          line-height: 24px;
        }

        .show {
          display: block;
          // visibility: visible;
        }
        .none {
          height: 24px;
          margin-bottom: 24px;
        }
      }

      .btn_box {
        // width: 370px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        bottom: 48px;
      }
      .dashboeard_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 464px;
        height: 48px;
        background: $neon-blue-500;
        border-radius: 100px;
        color: $gray-100;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        border: none;
        cursor: pointer;

        &:disabled {
          background: #e0dff8;
          cursor: default;
        }

        &_loading {
          animation: spin 1200ms infinite linear;
        }
      }

      @keyframes spin {
        0% {
          transform: rotate(0);
        }

        100% {
          transform: rotate(360deg);
        }
      }

      .cancellation {
        color: $neon-blue-500;
        font-size: 14px;
        font-weight: 700;
        // line-height: 22px;
        margin-top: 16px;
        cursor: pointer;
      }
      .DataCenterAnalyzingModalContainer {
        .dim {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100vh;
          background-color: rgba(221, 221, 221, 0.5);
          z-index: 10;
        }
      }
      .DataCenterAnalyzingModal {
        position: relative;
        display: flex;
        flex-direction: column;
        // align-items: center;
        // align-items: flex-end;
        // padding: 0px 24px 24px 0px;
        width: 480px;
        height: 205px;
        /* 최상단 위치 */
        z-index: 999;
        position: absolute;
        top: -75%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $white;
        box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        .alert_message {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 24px 48px 0 24px;
          .title {
            width: 408px;
            height: 64px;
            // text-align: center;
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            margin-bottom: 12px;
          }

          .sub {
            width: 408px;
            height: 24px;
            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
          }
        }
        .cancel_box {
          position: absolute;
          bottom: 24px;
          right: 24px;
          button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px 24px;
            gap: 2px;

            width: 93px;
            height: 45px;
            background: #4b43ff;
            border-radius: 100px;
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
          }
        }
      }
    }
  }
}

// 라디오 버튼
.radio {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border: 1px solid $gray-300;
  border-radius: 100px;

  &_circle {
    width: 8px;
    height: 8px;
    border-radius: 100px;
    background-color: $white;
  }

  &:hover {
    border: none;
    background-color: $neon-blue-300;
  }

  &.checked {
    border: none;
    background-color: $neon-blue-500;
  }
}
