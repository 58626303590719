@import 'scss/variables';
@import 'scss/typography';

.box {
  width: 100%;
  background-color: $white;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  &.retention-parameters {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    padding: 25px 24px 30px;
    & .row {
      display: flex;
      position: relative;
      flex-wrap: wrap;
      width: 100%;
      column-gap: 21.33px;
      row-gap: 20px;
      &.first {
        z-index: 3;
        margin-top: 0;
      }
      &.second {
        z-index: 2;
        margin-top: 10px;
      }

      &.fourth {
        display: flex;
        justify-content: center;
        margin-top: 30px;
        & .advanced-button {
          position: absolute;
          right: 0px;
        }
      }
    }
    .advanced-division {
      display: flex;
      position: relative;
      width: 100%;
      height: 36px;
      margin-top: 20px;
      svg {
        position: absolute;
        width: 100%;
        height: 100%;
      }
      .info {
        display: flex;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        align-items: center;
        justify-content: center;
        background-color: $white;
        height: 36px;
        padding-right: 15px;
        padding: 0px 12px;
        @include strong;
        color: $gray-500;
      }
      .tooltip-box {
        &:hover {
          z-index: 5;
        }
        position: absolute;
        left: 53%;
        top: 5px;
        align-items: center;
        justify-content: center;
        height: 36px;
        .tooltip-info-container {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          margin-left: 6px;
          background-color: $white;
          .tooltip {
            position: absolute;
            width: 220px;
            height: 56px;
            left: 0;
            top: -70px;
            padding: 8px 10px;
            background-color: $white;
            border-radius: 4px;
            filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.08))
              drop-shadow(2px 4px 12px rgba(0, 0, 0, 0.12));
            @include small-text;
            color: $gray-900;
          }
          .tooltip:after {
            content: '';
            position: absolute;
            left: 10px;
            bottom: -7px;
            width: 0;
            height: 0;
            border-top: 8px solid $white;
            border-right: 7px solid transparent;
            border-left: 7px solid transparent;
          }
        }
      }
    }
  }
  &.retention-rate-chart-container {
    margin-top: 24px;
    padding: 35px 24px 40px;
    height: 542px;
  }
}
.overview-skeleton-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}