@import 'scss/typography';
@import 'scss/variables';

.main-title {
  @include h6;
  color: $gray-900;
}

.sub-title {
  margin: 0;
  @include body-regular;
  color: $gray-500;
  &.hide {
    visibility: hidden;
  }
}
