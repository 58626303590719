@import 'scss/variables';
@import 'scss/typography';

.snack {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px 16px;
  width: 740px;
  height: 52px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08), 2px 4px 12px rgba(0, 0, 0, 0.12);

  &.green {
    background-color: $green-500;
  }
  &.red {
    background-color: $red-500;
  }
  &.blue {
    background-color: $neon-blue-500;
  }

  &.no-sidebar {
    left: 50%;
  }
  &.sidebar-minimized {
    left: calc(50% + 70px);
  }
  &.sidebar-expanded {
    left: calc(50% + 120px);
  }
  & img {
    margin-right: 10px;
  }
  & .message {
    color: $white;
    @include body-small;
  }
  & .close-button {
    position: absolute;
    right: 16px;
  }
  & .refresh-button {
    position: absolute;
    right: 16px;
    color: $white;
    @include button-small;
  }
}
