@import 'scss/variables';
@import 'scss/typography';

.overview-ranking-chart {
    position: relative;
    width: 100%;
    & > .legend {
        position: absolute;
        top: 58px;
        right: 10px;
        display: flex;
        align-items: center;
        @include chart-legend;
        & > .legend-color {
            margin-right: 5px;
            width: 9px;
            height: 9px;
            background-color: $neon-blue-500;
            border-radius: 5px;
        }
    }
    & > .overview-ranking-chart-title {
        display: flex;
        justify-content: center;
        @include chart-title;
    }
    & > .overview-ranking-chart-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 42px;
        & > .ranking-card {
            margin-top: 10px;
        }
    }
}