@import 'scss/typography';
@import 'scss/variables';

.box {
   width: 100%;
   height: auto;
   background-color:  $white;
   box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
   border-radius: 10px;
   &.forecasting-parameters {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    padding: 25px 24px 30px;
    & .row {
        display: flex;
        position: relative;
        &:nth-of-type(2) {
            display: flex;
            margin-top: 30px;
            justify-content: center;
        }
        & .search-input {
            margin-right: 15px;
        }
    }
}

   &.forecasting-bar-chart-container {
       margin-top: 24px;
       padding: 35px 40px 40px;
       height: 581px;
       & .title {
        display: flex;
        justify-content: center;
        margin-bottom: 35px;
        @include chart-title;
       }
    }
}
