@import 'scss/variables';
@import 'scss/typography';

.layout-footer {
  display: flex;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
  @include body-small;
  color: $gray-700;

  &.sidebar {
    margin-left: 240px;
    min-width: 1200px;
  }
}