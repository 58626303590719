@import 'scss/variables';

.NewMember {
  margin-bottom: 20px;
  width: 100%;
  height: calc(100% - 120px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  &_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 416px;
    height: 100%;

    &_logo {
      width: 192px;
      margin-bottom: 38px;
      &_img {
        width: 100%;
        height: 100%;
      }
    }
    &_insidebox {
      width: 416px;
      height: auto;
      min-height: 421px;
      box-shadow: 2px 0px 15px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      background: $white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0 58px;

      // fillemail
      .contents,
      .contentsWrapper > .contents {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 48px;
        margin-bottom: 48px;
        span {
          color: $neon-blue-500;
        }
        &_header {
          margin-bottom: 24px;
          font-family: 'Manrope';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: $gray-900;
        }
        &_text {
          margin-bottom: 24px;
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: $gray-900;
        }
        .title {
          width: 300px;
          height: 24px;
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: $gray-900;
          text-align: left;
          margin-bottom: 5px;
        }
        .inputbox {
          width: 300px;
          height: 56px;
          padding-left: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 1px solid #dddddd;
          border-radius: 4px;
          margin-bottom: 48px;
        }
        .invalid {
          margin-bottom: 4px;
          border: 1px solid $red-500;
        }
        .valid {
          margin-bottom: 8px;
          border: 1px solid $green-500;
        }
        .valid2 {
          margin-bottom: 30px;
          border: 1px solid $green-500;
        }
        .inputbox_text {
          width: 236px;
          height: 24px;
          border: none;
          &:focus {
            outline: none;
          }
        }
        .inputbox_error {
          width: 20px;
          height: 20px;
          margin: 17px 12.5px 17px 0;
        }
        .email_error {
          display: flex;
          width: 290px;
          padding-left: 16px;

          margin: 0 5px 20px 0;
          color: $red-500;
          font-size: 14px;
          line-height: 24px;
        }
        .error {
          display: flex;
          width: 290px;
          padding-left: 16px;

          margin: 0 5px 8px 0;
          color: $red-500;
          font-size: 14px;
          line-height: 24px;
        }
        .button_box {
          width: 300px;
          height: 48px;

          .send_button {
            width: 100%;
            height: 48px;
            justify-content: center;
            align-items: center;
            padding: 0px 24px;
            background: $neon-blue-50;
            border-radius: 100px;
            font-family: 'Manrope';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            align-items: center;
            text-align: center;
            color: $gray-100;
          }
          .deactive {
            background: $neon-blue-50;
          }
          .active {
            background: $neon-blue-500;
          }
        }
      }

      // contentsWrapper
      .contentsWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        .inputbox_text2 {
          width: 204px;
          height: 24px;
          border: none;
          margin-right: 8px;
          &:focus {
            outline: none;
          }
        }
        .check_box {
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          list-style-type: none;
          .check_list {
            margin-bottom: 4px;
            display: flex;
            align-items: center;
          }
          .check_list:last-child {
            margin-bottom: 24px;
          }
          .pass_alert {
            width: 18px;
            height: 18px;

            margin-right: 4px;
          }
        }
      }
      .retry {
        margin-top: 19px;
        font-family: 'Manrope';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        color: $neon-blue-500;
        cursor: pointer;
      }
      .eye {
        display: flex;
        align-items: center;
        margin-right: 12.5px;
      }
    }
  }
}
