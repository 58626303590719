@import 'scss/variables';
@import 'scss/typography';

.withheader-layout-container {
    width: 100%;
    min-height: 100vh;
    background-color: $gray-50;
    overflow-x: scroll;
    & .layout-main {
      position: relative;
      margin-top: 64px;
      background-color: $gray-50;
      & .main-contents {
        margin: 0 auto;
        padding-top: 48px;
        width: calc(1440px - 240px);
        background-color: $gray-50;
      }
    }
}