@import 'scss/variables';
@import 'scss/typography';

.base-layout-container {
    width: 100%;
    min-height: 100vh;
    background-color: $gray-50;
    overflow-x: scroll;
    & .layout-main {
        margin: 177px auto 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        min-width: 100%;
        background-color: $gray-50;
        height: 100%;
        max-height: 100vh;
        & .main-contents {
          margin: 0 auto ;
          width: 1152px;
        }
      }
}