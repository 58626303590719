@import 'scss/typography';
@import 'scss/variables';

.UserBased {
  display: flex;
  flex-direction: column;

  &_top_ranking_descriptions {
    margin-bottom: 8px;
    padding-left: 8px;
    @include body-small;
    color: $gray-900;
    list-style-position: inside;
  }

  &_title {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: $gray-900;

    margin: 0;
  }
  &_description {
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $gray-500;
  }
  &_search_box {
    display: flex;
    gap: 20px;
    flex-direction: column;

    width: 1152px;

    margin-top: 32px;

    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    background-color: $white;
    padding: 24px;

    .search-input {
      margin-right: 15px;
      margin-bottom: 20px;
    }
    .button_layer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 56px;

      &::before {
        content: '';
      }
    }
    .search_input_row {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &_small_table_box {
    margin-top: 24px;

    padding: 24px;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    background-color: $white;

    .UserBased_box_title {
      position: relative;
      color: $gray-900;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      text-align: center;
      padding-top: 15px;
      margin-top: 0 !important;
      margin-bottom: 20;
      vertical-align: middle;
      align-items: center;

      .info_box {
        display: none;
      }
      .info_logo_user {
        width: 24px;
        height: 24px;

        img {
          width: 24px;
          height: 24px;
        }
      }
      .info_logo_user:hover + .info_box {
        display: block;
        position: absolute;
        right: 30px;
        top: -60px;
        width: 227px;
        padding: 8px 10px;
        border-radius: 4px;
        background-color: $white;
        color: $gray-900;
        text-align: left;
        font-family: 'Pretendard';
        font-style: normal;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;

        filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.08))
          drop-shadow(2px 4px 12px rgba(0, 0, 0, 0.12));

        //말풍선 윗부분 삼각형
        .triangle {
          position: absolute;
          bottom: -4px;
          left: 190px;
          width: 12px;
          height: 12px;
          transform: rotate(45deg);
          background-color: $white;
        }
      }
    }

    .UserBased_box_export {
      display: flex;
      flex-direction: row;

      width: 100%;
      color: $neon-blue-500;
      text-align: right;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 15px;
      // margin-bottom: 12px;

      justify-content: right;
      cursor: pointer;

      .export_img {
        width: 15px;
        height: 15px;
        margin-right: 2px;
      }
    }
    .csv_help_text {
      width: 362px;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      align-items: center;
      color: $gray-900;
    }
    .table_body_row .latest_transaction {
      padding-left: 36px;
    }
  }
  &_table_box {
    margin-top: 24px;

    padding: 24px;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    background-color: $white;

    .UserBased_box_title {
      color: $gray-900;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
      text-align: center;
      padding-top: 6px;
    }

    .UserBased_box_export {
      display: flex;
      flex-direction: row;

      color: $neon-blue-500;
      text-align: right;
      font-family: 'Manrope';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 15px;
      // margin-bottom: 12px;
      justify-content: right;
      cursor: pointer;

      .export_img {
        width: 15px;
        height: 15px;
        margin-right: 2px;
      }
    }
    .csv_help_text {
      display: flex;
      padding: 16px;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      align-items: center;
      color: $gray-900;
    }
    .table_body_row .latest_transaction {
      padding-left: 36px;
    }
  }
}
