@import 'scss/variables.scss';

.TableUserBase {
  table {
    max-width: 100%;
    border-collapse: collapse;
    border-top: 1px solid $gray-300;

  }

  .table_head_row {
    background-color: $gray-100;
    border-bottom: solid $gray-300 1px;
    color: $gray-700;
    font-size: 14px;
    text-align: center;
    font-family: 'Pretendard';
    font-style: normal;
  }
  .logo_box {
    display: inline;
    width: 17px;
    height: 17px;
    position: relative;
  }
  .info_logo {
    width: 17px;
    height: 17px;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    margin-left: 3px;
  }

  // 인포 텍스트 관련
  .info_box {
    display: none;
  }

  .logo_box:hover + .info_box {
    display: block;
    position: absolute;
    left: 2px;
    top: -22px;
    width: 327px;
    padding: 8px 10px;
    border-radius: 4px;
    background-color: $white;
    color: $gray-900;
    font-family: 'Pretendard';
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;

    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.08))
      drop-shadow(2px 4px 12px rgba(0, 0, 0, 0.12));

    //말풍선 윗부분 삼각형
    .triangle {
      position: absolute;
      bottom: -4px;
      left: 150px;
      width: 12px;
      height: 12px;
      transform: rotate(45deg);
      background-color: $white;
    }
  }

  th {
    min-width: 84px;
    height: 34px;
    position: relative;

    &.largeWidth {
      width: 320px;
      max-width: 320px;
    }
    &.everageWidth {
      width: 99px;
      max-width: 99px;
    }
  }

  .table_body_row {
    height: 50px;
    min-height: 50px;
    max-height: 50px;
    border-bottom: solid $gray-300 1px;
    color: $gray-900;
    font-size: 14px;
    line-height: 18x;
  
    font-family: 'Pretendard';
    font-style: normal;
    &:hover {
      background-color: $neon-blue-50;
    }
    .itemText {

      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      margin: 0;
    }
    .table_td {
      padding: 0px 10px;
      text-align: center;

      &:first-child {
        width: 320px;
        max-width: 320px;
        padding: 0 16px;
      }
      .latest_transaction {
        text-align: left;
      }
      span {
        color: $gray-500;
      }
    }
  }
  & .no-data {
    // margin-top: 50px;
  }
}
