@import 'scss/variables';
@import 'scss/typography';

.ranking-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    width: 100%;
    height: 42px;
    border-radius: 6px;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.07);
    & > .ranking-card-name {
        @include chart-body;
    }
    & > .ranking-card-value {
        @include chart-tooltip-small;
    }

    &.primary {
        background-color: $neon-blue-500;
        color: $white;
    }
    &.secondary {
        background-color: $neon-blue-300;
        color: $gray-900;
    }
    &.tertiary {
        background-color: $neon-blue-100;
        color: $gray-900;
    }
    &.etc {
        background-color: $white;
        color: $gray-900;
    }
}